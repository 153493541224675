import { createAction } from '@reduxjs/toolkit'
import { IMqttRequest } from '../../../api/types'
import {
  MQTT_CLEAN_ERROR_REQUEST,
  MQTT_CLEAN_SUCCESS_REQUEST,
  MQTT_ERROR,
  MQTT_REQUEST,
  MQTT_SUCCESS,
} from './actionTypes'

export const mqttRequest = createAction<IMqttRequest>(MQTT_REQUEST)
export const mqttSuccess = createAction(MQTT_SUCCESS)
export const mqttError = createAction<unknown>(MQTT_ERROR)

export const mqttCleanSuccessRequest = createAction(MQTT_CLEAN_SUCCESS_REQUEST)
export const mqttCleanErrorRequest = createAction(MQTT_CLEAN_ERROR_REQUEST)
