export const GROUPS_REQUEST = '@api/GROUPS_REQUEST'
export const GROUPS_SUCCESS = '@api/GROUPS_SUCCESS'
export const GROUPS_ERROR = '@api/GROUPS_ERROR'

export const GROUP_REQUEST = '@api/GROUP_REQUEST'
export const GROUP_SUCCESS = '@api/GROUP_SUCCESS'
export const GROUP_ERROR = '@api/GROUP_ERROR'

export const GROUP_CLEAN_REQUEST = '@api/GROUP_CLEAN_REQUEST'

export const GROUP_CREATE_REQUEST = '@api/GROUP_CREATE_REQUEST'
export const GROUP_CREATE_SUCCESS = '@api/GROUP_CREATE_SUCCESS'
export const GROUP_CREATE_ERROR = '@api/GROUP_CREATE_ERROR'

export const GROUP_UPDATE_REQUEST = '@api/GROUP_UPDATE_REQUEST'
export const GROUP_UPDATE_SUCCESS = '@api/GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_ERROR = '@api/GROUP_UPDATE_ERROR'

export const GROUP_DELETE_REQUEST = '@api/GROUP_DELETE_REQUEST'
export const GROUP_DELETE_SUCCESS = '@api/GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_ERROR = '@api/GROUP_DELETE_ERROR'

export const GROUPS_CLEAN_SUCCESS_REQUEST = '@api/GROUPS_CLEAN_SUCCESS_REQUEST'
export const GROUPS_CLEAN_ERROR_REQUEST = '@api/GROUPS_CLEAN_ERROR_REQUEST'
