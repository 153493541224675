import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { keyCreate } from '../../../api/keyCreate'
import { Acces_key, KeyFormValues } from '../../../types'
import { keyCreateError, keyCreateSuccess } from '../../../actions/hipUsers/actions'

export function* sagaKeyCreate({ payload }: Action<KeyFormValues>) {
  try {
    const response: Acces_key = yield call(keyCreate, payload)
    yield put(keyCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(keyCreateError(error.response.data as unknown))
    } else {
      yield put(keyCreateError(error))
      throw error
    }
  }
}
