import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './root'
import { Tasks } from '../../../types'
import { taskDetailError, taskDetailSuccess } from '../actions/tasks/actions'
import { getTaskDetail } from '../../api/tasks/taskDetail'

export function* sagaGetTask({ payload }: Action<{ taskId: string; type: string }>) {
  try {
    const response: Tasks = yield call(getTaskDetail, payload)
    yield put(taskDetailSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(taskDetailError(error.response.data as unknown))
    } else {
      yield put(taskDetailError(error))
      throw error
    }
  }
}
