import { FormControl, FormGroup, FormLabel } from '@mui/material'
import { Control, Controller } from 'react-hook-form'

import ControlCheckboxList from './ControlCheckboxList'
import { ICheckboxItem } from './types'

interface IProps {
  control: Control
  'data-e2e'?: string
  id: string
  items?: ICheckboxItem[]
  label?: string
}

const FormCheckboxGroup = ({ control, id, items, label, ...props }: IProps) => {
  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      <FormGroup data-e2e={props['data-e2e']}>
        {!!items?.length && (
          <Controller
            control={control}
            name={id}
            render={({ field }) => (
              <ControlCheckboxList data-e2e={props['data-e2e']} field={field} items={items} />
            )}
          />
        )}
      </FormGroup>
    </FormControl>
  )
}

export default FormCheckboxGroup
