import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'
import { Api } from '@mui/icons-material'

export type IServiceState = 'OK' | 'warn' | 'error' | 'OFF'

export interface IServiceStatusProps {
  desc?: string
  icon?: any
  name: string
  state: IServiceState
}
const stateTexts = {
  OK: 'Fully operational',
  warn: 'There are warnings',
  error: 'There are errors',
  OFF: 'OFF',
}

const descByState = (state: IServiceState) => (state in stateTexts ? stateTexts[state] : 'Unknown')

export const ServiceStatus = ({ desc, icon, name, state }: IServiceStatusProps) => {
  return (
    <List component="div" disablePadding>
      <ListItem>
        <ListItemAvatar>{icon ? icon : <Api />}</ListItemAvatar>
        <ListItemText primary={desc ? desc : descByState(state)} secondary={name + ' status'} />
      </ListItem>
    </List>
  )
}
