import { createAction } from '@reduxjs/toolkit'
import {
  IDeviceType,
  IpDevice,
  NewIpDevice,
  PaginatedResponse,
  VolumeUpdate,
} from '../../../../types'
import {
  DEVICES_ERROR,
  DEVICES_REQUEST,
  DEVICES_SUCCESS,
  IPDEVICES_REQUEST,
  IPDEVICES_SUCCESS,
  IPDEVICES_ERROR,
  DEVICE_CLEAN_ERROR_REQUEST,
  DEVICE_CLEAN_SUCCESS_REQUEST,
  DEVICE_UPDATE_ERROR,
  DEVICE_UPDATE_REQUEST,
  DEVICE_UPDATE_SUCCESS,
  VOLUME_UPDATE_ERROR,
  VOLUME_UPDATE_REQUEST,
  VOLUME_UPDATE_SUCCESS,
  DEVICE_CREATE_REQUEST,
  DEVICE_CREATE_SUCCESS,
  DEVICE_CREATE_ERROR,
  DEVICES_TYPES_REQUEST,
  DEVICES_TYPES_SUCCESS,
  DEVICES_TYPES_ERROR,
  DEVICE_DETAIL_REQUEST,
  DEVICE_DETAIL_SUCCESS,
  DEVICE_DETAIL_ERROR,
  DEVICE_DELETE_REQUEST,
  DEVICE_DELETE_SUCCESS,
  DEVICE_DELETE_ERROR,
  DEVICE_CLEAN_REQUEST,
} from './actionTypes'
import { PageSet, deletedItem } from '../../../../hip/types'

//getAll ipDevices
export const ipdevicesRequest = createAction<PageSet>(IPDEVICES_REQUEST)
export const ipdevicesSuccess = createAction<PaginatedResponse>(IPDEVICES_SUCCESS)
export const ipdevicesError = createAction<any>(IPDEVICES_ERROR)

export const devicesTypesRequest = createAction(DEVICES_TYPES_REQUEST)
export const devicesTypesSuccess = createAction<IDeviceType[]>(DEVICES_TYPES_SUCCESS)
export const devicesTypesError = createAction<any>(DEVICES_TYPES_ERROR)

//getAll devices by type
export const devicesRequest = createAction<{ pageSet: PageSet; type: string }>(DEVICES_REQUEST)
export const devicesSuccess = createAction<PaginatedResponse>(DEVICES_SUCCESS)
export const devicesError = createAction<any>(DEVICES_ERROR)

export const deviceCleanRequest = createAction(DEVICE_CLEAN_REQUEST)

export const deviceDetailRequest = createAction<number>(DEVICE_DETAIL_REQUEST)
export const deviceDetailSuccess = createAction<IpDevice>(DEVICE_DETAIL_SUCCESS)
export const deviceDetailError = createAction<any>(DEVICE_DETAIL_ERROR)

export const deviceCreateRequest = createAction<{ body: NewIpDevice; type: string }>(
  DEVICE_CREATE_REQUEST,
)
export const deviceCreateSuccess = createAction<IpDevice>(DEVICE_CREATE_SUCCESS)
export const deviceCreateError = createAction<any>(DEVICE_CREATE_ERROR)

export const deviceUpdateRequest = createAction<{ body: any; id: number; type: string }>(
  DEVICE_UPDATE_REQUEST,
)
export const deviceUpdateSuccess = createAction<IpDevice>(DEVICE_UPDATE_SUCCESS)
export const deviceUpdateError = createAction<any>(DEVICE_UPDATE_ERROR)

export const deviceDeleteRequest = createAction<number>(DEVICE_DELETE_REQUEST)
export const deviceDeleteSuccess = createAction<deletedItem>(DEVICE_DELETE_SUCCESS)
export const deviceDeleteError = createAction<any>(DEVICE_DELETE_ERROR)

// VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
export const volumeUpdateRequest = createAction<{ body: VolumeUpdate; id: number }>(
  VOLUME_UPDATE_REQUEST,
)
export const volumeUpdateSuccess = createAction<IpDevice>(VOLUME_UPDATE_SUCCESS)
export const volumeUpdateError = createAction<any>(VOLUME_UPDATE_ERROR)

export const deviceCleanSuccessRequest = createAction(DEVICE_CLEAN_SUCCESS_REQUEST)
export const deviceCleanErrorRequest = createAction(DEVICE_CLEAN_ERROR_REQUEST)
