import { Select, MenuItem, IconButton } from '@mui/material'
import { SortByAlpha } from '@mui/icons-material'
import { useRef } from 'react'

export type IDeviceOrderingValues = 'id' | 'desc'

export interface IDeviceOrdering {
  orderBy: IDeviceOrderingValues
  ascendent: boolean
}

export interface IDeviceOrderingEvent {
  element: React.MutableRefObject<JSX.Element>
  ordering: IDeviceOrdering
}

interface IProps {
  onOrderChange?: (ev: IDeviceOrderingEvent) => void
  ordering?: IDeviceOrdering
  allowById?: boolean
}

export const DeviceSortList = (props: IProps) => {
  const element = useRef(null)
  const ordering = props.ordering ?? { orderBy: 'desc', ascendent: true }
  const allowById = props?.allowById ?? true

  const orderDidChanged = () => {
    const ev = { ordering: Object.assign({}, ordering), element }
    if (props.onOrderChange) {
      try {
        props.onOrderChange(ev)
      } catch (ex) {
        console.error(ex)
      }
    }
  }
  const setOrderBy = (value: IDeviceOrderingValues) => {
    ordering.orderBy = allowById ? value : 'desc'
    orderDidChanged()
  }
  const setAscendent = (value: boolean) => {
    ordering.ascendent = value
    orderDidChanged()
  }
  return (
    <>
      {' '}
      {allowById && (
        <Select
          label="Order By"
          defaultValue={ordering.orderBy}
          size="small"
          onChange={(event) => setOrderBy(event.target.value as IDeviceOrderingValues)}
        >
          <MenuItem value="desc">Description</MenuItem>
          <MenuItem value="id">Device ID</MenuItem>
        </Select>
      )}
      <IconButton onClick={() => setAscendent(!ordering.ascendent)}>
        <SortByAlpha />
      </IconButton>
    </>
  )
}
