import { createAction } from '@reduxjs/toolkit'
import { ILicenseRequest, ILicenseResponse } from '../../../api/types'
import {
  LICENSE_CLEAN_ERROR_REQUEST,
  LICENSE_CLEAN_SUCCESS_REQUEST,
  LICENSE_ERROR,
  LICENSE_REQUEST,
  LICENSE_SUCCESS,
  UPDATE_LICENSE_ERROR,
  UPDATE_LICENSE_REQUEST,
  UPDATE_LICENSE_SUCCESS,
} from './actionTypes'

export const licenseRequest = createAction(LICENSE_REQUEST)
export const licenseSuccess = createAction<ILicenseResponse>(LICENSE_SUCCESS)
export const licenseError = createAction<unknown>(LICENSE_ERROR)

export const updateLicenseRequest = createAction<ILicenseRequest>(UPDATE_LICENSE_REQUEST)
export const updateLicenseSuccess = createAction<ILicenseResponse>(UPDATE_LICENSE_SUCCESS)
export const updateLicenseError = createAction<unknown>(UPDATE_LICENSE_ERROR)

export const licenseCleanSuccessRequest = createAction(LICENSE_CLEAN_SUCCESS_REQUEST)
export const licenseCleanErrorRequest = createAction(LICENSE_CLEAN_ERROR_REQUEST)
