import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { rcuDelete } from '../../../api/rcuDelete'
import { rcuDeleteSuccess, rcuDeleteError } from '../../../actions/rcus/actions'
import { Id, deletedItem } from '../../../types'

export function* sagaRcuDelete({ payload }: Action<Id>) {
  try {
    const response: deletedItem = yield call(rcuDelete, payload)
    yield put(rcuDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(rcuDeleteError(error.response.data as unknown))
    } else {
      yield put(rcuDeleteError(error))
      throw error
    }
  }
}
