export const TASKS_REQUEST = '@api/TASKS_REQUEST'
export const TASKS_SUCCESS = '@api/TASKS_SUCCESS'
export const TASKS_ERROR = '@api/TASKS_ERROR'

export const EXCEPTION_REQUEST = '@api/EXCEPTION_REQUEST'
export const EXCEPTION_SUCCESS = '@api/EXCEPTION_SUCCESS'
export const EXCEPTION_ERROR = '@api/EXCEPTION_ERROR'

export const EXCEPTIONS_CREATE_REQUEST = '@api/EXCEPTIONS_CREATE_REQUEST'
export const EXCEPTIONS_CREATE_SUCCESS = '@api/EXCEPTIONS_CREATE_SUCCESS'
export const EXCEPTIONS_CREATE_ERROR = '@api/EXCEPTIONS_CREATE_ERROR'

export const TASK_DETAIL_REQUEST = '@api/TASK_DETAIL_REQUEST'
export const TASK_DETAIL_SUCCESS = '@api/TASK_DETAIL_SUCCESS'
export const TASK_DETAIL_ERROR = '@api/TASK_DETAIL_ERROR'

export const TASK_CREATE_REQUEST = '@api/TASK_CREATE_REQUEST'
export const TASK_CREATE_SUCCESS = '@api/TASK_CREATE_SUCCESS'
export const TASK_CREATE_ERROR = '@api/TASK_CREATE_ERROR'

export const TASK_UPDATE_REQUEST = '@api/TASK_UPDATE_REQUEST'
export const TASK_UPDATE_SUCCESS = '@api/TASK_UPDATE_SUCCESS'
export const TASK_UPDATE_ERROR = '@api/TASK_UPDATE_ERROR'

export const TASK_DELETE_REQUEST = '@api/TASK_DELETE_REQUEST'
export const TASK_DELETE_SUCCESS = '@api/TASK_DELETE_SUCCESS'
export const TASK_DELETE_ERROR = '@api/TASK_DELETE_ERROR'

export const TASK_CLEAN_SUCCESS_REQUEST = '@api/TASK_CLEAN_SUCCESS_REQUEST'
export const TASK_CLEAN_ERROR_REQUEST = '@api/TASK_CLEAN_ERROR_REQUEST'
