import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { Acces_key, KeyFormValues } from '../../../types'
import { keyUpdateError, keyUpdateSuccess } from '../../../actions/hipUsers/actions'
import { keyUpdate } from '../../../api/keyUpdate'

export function* sagaKeyUpdate({ payload }: Action<{ updatedData: KeyFormValues; keyId: number }>) {
  try {
    const { updatedData, keyId } = payload
    const response: Acces_key = yield call(keyUpdate, updatedData, keyId)
    yield put(keyUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(keyUpdateError(error.response.data as unknown))
    } else {
      yield put(keyUpdateError(error))
      throw error
    }
  }
}
