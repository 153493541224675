import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import ButtonPanel from '../dashboard/components/ButtonPanel'
import { useNavigate } from 'react-router-dom'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const Video = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [videoElement, setVideoElement] = useState<HTMLVideoElement>(null)
  let webrtcPeerConnection: RTCPeerConnection = null
  let websocket: WebSocket = null
  let reportError: any
  const webrtcConfig = {
    iceServers: [
      {
        urls: ['stun:stun.l.google.com:19302'],
      },
    ],
  }

  const onLocalDescription = (desc: any) => {
    console.log('Local description: ' + JSON.stringify(desc))
    webrtcPeerConnection
      .setLocalDescription(desc)
      .then(function () {
        websocket.send(JSON.stringify({ type: 'sdp', data: webrtcPeerConnection.localDescription }))
      })
      .catch(reportError)
  }

  const onIncomingSDP = (sdp: any) => {
    console.log('Incoming SDP: ' + JSON.stringify(sdp))
    webrtcPeerConnection.setRemoteDescription(sdp).catch(reportError)
    webrtcPeerConnection.createAnswer().then(onLocalDescription).catch(reportError)
  }

  const onIncomingICE = (ice: any) => {
    const candidate = new RTCIceCandidate(ice)
    console.log('Incoming ICE: ' + JSON.stringify(ice))
    webrtcPeerConnection.addIceCandidate(candidate).catch(reportError)
  }

  const onAddRemoteStream = (event: RTCTrackEvent) => {
    const video = document.getElementById('stream') as HTMLVideoElement
    video.srcObject = event.streams[0]
  }

  const onIceCandidate = (event: RTCPeerConnectionIceEvent) => {
    if (event.candidate == null) return

    console.log('Sending ICE candidate out: ' + JSON.stringify(event.candidate))
    websocket.send(JSON.stringify({ type: 'ice', data: event.candidate }))
  }

  const onServerMessage = (event: MessageEvent) => {
    const message = JSON.parse(event.data)
    if (!webrtcPeerConnection) {
      webrtcPeerConnection = new RTCPeerConnection(webrtcConfig)
      webrtcPeerConnection.ontrack = onAddRemoteStream
      webrtcPeerConnection.onicecandidate = onIceCandidate
    }
    switch (message.type) {
      case 'sdp':
        onIncomingSDP(message.data)
        break
      case 'ice':
        onIncomingICE(message.data)
        break
      default:
        break
    }
  }

  const playStream = () => {
    const host = window.location.hostname.replace('app', 'video')
    const search = window.location.search
    const url = `wss://${host}/ws${search}`
    websocket = new WebSocket(url)
    websocket.addEventListener('message', onServerMessage)
  }

  useEffect(() => {
    setVideoElement(document.getElementById('stream') as HTMLVideoElement)
    playStream()
  }, [])

  const handleOnClick = () => {
    navigate('/mobile/dashboard')
  }

  return (
    <Box>
      <Box mt={2}>
        <ButtonPanel
          widthAndHeight={80}
          variant="contained"
          color={'primary'}
          onClick={handleOnClick}
        >
          <ArrowBackIcon
            sx={{
              width: '100%',
              height: '100%',
            }}
          />
        </ButtonPanel>
      </Box>
      <video id="stream" autoPlay playsInline width="100%" height={480} />
    </Box>
  )
}

export default Video
