import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { rcuCreate } from '../../../api/rcuCreate'
import { Action } from '../../../../common/store/sagas/root'
import { Rcu, RcuFormValues } from '../../../types'
import { rcuCreateError, rcuCreateSuccess } from '../../../actions/rcus/actions'

export function* sagaRcuCreate({ payload }: Action<RcuFormValues>) {
  try {
    const response: Rcu = yield call(rcuCreate, payload)
    yield put(rcuCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(rcuCreateError(error.response.data as unknown))
    } else {
      yield put(rcuCreateError(error))
      throw error
    }
  }
}
