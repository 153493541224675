import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './../root'
import { IpDevice } from '../../../../types'
import { deviceDetailError, deviceDetailSuccess } from '../../actions/devices/actions'
import { getDeviceDetail } from '../../../api/devices/deviceDetail'

export function* sagaGetDevice({ payload }: Action<number>) {
  try {
    const response: IpDevice = yield call(getDeviceDetail, payload)
    yield put(deviceDetailSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(deviceDetailError(error.response.data as unknown))
    } else {
      yield put(deviceDetailError(error))
      throw error
    }
  }
}
