import Admin from './Admin'
import Dashboard2 from './dashboard/Dashboard2'
import RoomsBoard from './hip/boards/RoomsBoard'
import SignIn from './SigIn'
import Video from './video/Video'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, useNavigate, useLocation, useSearchParams } from 'react-router-dom'
import { Alert, Collapse, IconButton } from '@mui/material'
import { Close } from '@mui/icons-material'
import { makeStyles } from 'tss-react/mui'
import './i18n/config'
import { userInfoRequest, userNotificationTokenRequest } from './common/store/actions/user/actions'
import { getUserInfo } from './common/store/selectors/user'
import { getAPIService } from './getServerHost'
import { licenseRequest } from './common/store/actions/license/action'
import { userProfileRequest } from './common/store/actions/profile/actions'

axios.defaults.withCredentials = true

const useStyles = makeStyles()(() => ({
  collapse: {
    display: 'flex',
    justifyContent: 'center',
  },
}))

export default function App() {
  const { classes } = useStyles()
  const userInfoState = useSelector(getUserInfo)
  const navigate = useNavigate()
  const [alert, setAlert] = useState(false)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const [searchParams] = useSearchParams()

  const closeAlert = () => {
    setAlert(false)
  }

  useEffect(() => {
    const { username, password } = {
      username: searchParams.get('username'),
      password: searchParams.get('password'),
    }
    console.log('username', username, 'password', password)
    if (username && password) {
      const androidToken = new URLSearchParams(window.location.search).get('android-token')
      const userLogin = {
        user: username,
        passw: password,
      }
      fetch(`${getAPIService}/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userLogin),
        credentials: 'include',
      })
        .then((response) => response.json())
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((data) => {
          dispatch(userProfileRequest({ user_id: data.id.toString() }))
          dispatch(userInfoRequest({ username: 'me' }))
          dispatch(licenseRequest())
          if (androidToken) {
            dispatch(
              userNotificationTokenRequest({
                token: androidToken,
                user: userLogin.user,
                password: userLogin.passw,
              }),
            )
          }
          navigate(location.pathname)
        })
        .catch(() => {
          setAlert(true)
          setTimeout(closeAlert, 3000)
        })
    }
  }, [searchParams])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>, location: string) {
    event.preventDefault()
    const androidToken = new URLSearchParams(window.location.search).get('android-token')
    const userLogin = {
      user: '',
      passw: '',
    }
    const target = event.target as HTMLFormElement
    const formElements = target.elements
    const elemArray = Array.from(formElements)
    elemArray.forEach((element) => {
      const inputElement = element as HTMLInputElement
      if (inputElement.name == 'username') userLogin.user = inputElement.value
      if (inputElement.name == 'password') userLogin.passw = inputElement.value
    })
    fetch(`${getAPIService}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(userLogin),
      credentials: 'include',
    })
      .then((response) => response.json())
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .then((data) => {
        dispatch(userProfileRequest({ user_id: data.id.toString() }))
        dispatch(userInfoRequest({ username: 'me' }))
        dispatch(licenseRequest())
        if (androidToken) {
          dispatch(
            userNotificationTokenRequest({
              token: androidToken,
              user: userLogin.user,
              password: userLogin.passw,
            }),
          )
        }
        console.log('redirect', location)
        location && navigate(location)
      })
      .catch(() => {
        setAlert(true)
        setTimeout(closeAlert, 3000)
      })
  }

  const login = userInfoState?.id && userInfoState?.active

  return (
    <>
      <Routes>
        {!login ? (
          <>
            <Route
              path="/*"
              element={<SignIn handleSubmit={handleSubmit} location={location.pathname} />}
            ></Route>
          </>
        ) : (
          <Route path="/*" element={<Admin />}></Route>
        )}
        {!login ? (
          <Route
            path="/roomsboard"
            element={<SignIn handleSubmit={handleSubmit} location={location.pathname} />}
          ></Route>
        ) : (
          <>
            <Route path="/roomsboard" element={<RoomsBoard />} />
            <Route path="/mobile/roomsboard" element={<RoomsBoard isMobileMode />} />
          </>
        )}
        {!login ? (
          <Route
            path="/dashboard"
            element={<SignIn handleSubmit={handleSubmit} location={location.pathname} />}
          ></Route>
        ) : (
          <>
            <Route path="/dashboard" element={<Dashboard2 />} />
            <Route path="/mobile/dashboard" element={<Dashboard2 isMobileMode />} />
          </>
        )}
        {!login ? (
          <Route
            path="/video"
            element={<SignIn handleSubmit={handleSubmit} location={location.pathname} />}
          ></Route>
        ) : (
          <>
            <Route path="/video" element={<Video />} />
          </>
        )}
      </Routes>
      <Collapse className={classes.collapse} in={alert}>
        <Alert severity="error">
          {t('signin_error')}

          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setAlert(false)
            }}
          >
            <Close fontSize="inherit" />
          </IconButton>
        </Alert>
      </Collapse>
    </>
  )
}
