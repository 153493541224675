import { createAction } from '@reduxjs/toolkit'
import {
  GROUPS_CLEAN_ERROR_REQUEST,
  GROUPS_CLEAN_SUCCESS_REQUEST,
  GROUPS_REQUEST,
  GROUPS_SUCCESS,
  GROUPS_ERROR,
  GROUP_REQUEST,
  GROUP_SUCCESS,
  GROUP_ERROR,
  GROUP_CREATE_REQUEST,
  GROUP_CREATE_SUCCESS,
  GROUP_CREATE_ERROR,
  GROUP_UPDATE_REQUEST,
  GROUP_UPDATE_SUCCESS,
  GROUP_UPDATE_ERROR,
  GROUP_DELETE_REQUEST,
  GROUP_DELETE_SUCCESS,
  GROUP_DELETE_ERROR,
  GROUP_CLEAN_REQUEST,
} from './actionTypes'
import { Group, NewZone } from '../../../api/types'
import { deletedItem } from '../../../../hip/types'

export const groupsRequest = createAction(GROUPS_REQUEST)
export const groupsSuccess = createAction<Group[]>(GROUPS_SUCCESS)
export const groupsError = createAction<any>(GROUPS_ERROR)

export const groupRequest = createAction<number>(GROUP_REQUEST)
export const groupSuccess = createAction<Group>(GROUP_SUCCESS)
export const groupError = createAction<any>(GROUP_ERROR)

export const groupCleanRequest = createAction(GROUP_CLEAN_REQUEST)

export const groupCreateRequest = createAction<NewZone>(GROUP_CREATE_REQUEST)
export const groupCreateSuccess = createAction<Group>(GROUP_CREATE_SUCCESS)
export const groupCreateError = createAction<any>(GROUP_CREATE_ERROR)

export const groupUpdateRequest = createAction<{ body: NewZone; id: number }>(GROUP_UPDATE_REQUEST)
export const groupUpdateSuccess = createAction<Group>(GROUP_UPDATE_SUCCESS)
export const groupUpdateError = createAction<any>(GROUP_UPDATE_ERROR)

export const groupDeleteRequest = createAction<number>(GROUP_DELETE_REQUEST)
export const groupDeleteSuccess = createAction<deletedItem>(GROUP_DELETE_SUCCESS)
export const groupDeleteError = createAction<any>(GROUP_DELETE_ERROR)

export const groupsCleanSuccessRequest = createAction(GROUPS_CLEAN_SUCCESS_REQUEST)
export const groupsCleanErrorRequest = createAction(GROUPS_CLEAN_ERROR_REQUEST)
