import { call, put } from '@redux-saga/core/effects'
import { groupCreateError, groupCreateSuccess } from '../actions/groups/actions'
import { Group, NewZone } from '../../api/types'
import axios from 'axios'
import { Action } from './root'
import { createGroup } from '../../api/groups/groupCreate'

export function* sagaCreateGroup({ payload }: Action<NewZone>) {
  try {
    const response: Group = yield call(createGroup, payload)
    yield put(groupCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(groupCreateError(error.response.data as unknown))
    } else {
      yield put(groupCreateError(error))
      throw error
    }
  }
}
