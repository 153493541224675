import axios from 'axios'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material'
import ButtonPanel from './ButtonPanel'
import { Engineering } from '@mui/icons-material'
import { getAPIService } from '../../getServerHost'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

const FixProcessBug = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState('')

  const handleOpenDialog = () => {
    setPassword('')
    setOpen(true)
  }

  const handleCloseDialog = () => setOpen(false)

  const handlePasswordSubmit = () => {
    if (password === '7878') {
      axios.delete(`${getAPIService}/paging/delete_all_processes`).then(() => {
        enqueueSnackbar('All fixed!!', { variant: 'success' })
      })
      handleCloseDialog()
    } else {
      enqueueSnackbar('Incorrect password', { variant: 'error' })
    }
  }

  return (
    <>
      <ButtonPanel
        variant="contained"
        widthAndHeight={72}
        color="primary"
        onClick={handleOpenDialog}
        tooltip="Fix message bug"
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Box m={4} width="100%" height="100%">
            <Engineering
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </ButtonPanel>

      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>Enter Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordSubmit} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default FixProcessBug
