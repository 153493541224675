import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { rcuUpdate } from '../../../api/rcuUpdate'
import { Action } from '../../../../common/store/sagas/root'
import { rcuUpdateError, rcuUpdateSuccess } from '../../../actions/rcus/actions'
import { Rcu } from '../../../types'

export function* sagaRcuUpdate({ payload }: Action<Rcu>) {
  try {
    const response: Rcu = yield call(rcuUpdate, payload)
    yield put(rcuUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(rcuUpdateError(error.response.data as unknown))
    } else {
      yield put(rcuUpdateError(error))
      throw error
    }
  }
}
