import { call, put } from '@redux-saga/core/effects'
import { Action } from './../root'
import axios from 'axios'
import { PaginatedResponse } from '../../../../types'
import { getDevices } from '../../../api/devices/devices'
import { devicesError, devicesSuccess } from '../../actions/devices/actions'
import { PageSet } from '../../../../hip/types'

export function* sagaGetDevices({ payload }: Action<{ pageSet: PageSet; type: string }>) {
  try {
    const response: PaginatedResponse = yield call(getDevices, payload)
    yield put(devicesSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(devicesError(error.response.data as unknown))
    } else {
      yield put(devicesError(error))
      throw error
    }
  }
}
