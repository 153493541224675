export const RCUS_REQUEST = '@api/RCUS_REQUEST'
export const RCUS_SUCCESS = '@api/RCUS_SUCCESS'
export const RCUS_ERROR = '@api/RCUS_ERROR'

export const RCU_DETAIL_REQUEST = '@api/RCU_DETAIL_REQUEST'
export const RCU_DETAIL_SUCCESS = '@api/RCU_DETAIL_SUCCESS'
export const RCU_DETAIL_ERROR = '@api/RCU_DETAIL_ERROR'

export const AVAILABlE_RCUS_REQUEST = '@api/AVAILABLE_RCUS_REQUEST'
export const AVAILABLE_RCUS_SUCCESS = '@api/AVAILABLE_RCUS_SUCCESS'
export const AVAILABLE_RCUS_ERROR = '@api/AVAILABLE_RCUS_ERROR'

export const RCU_CREATE_REQUEST = '@api/RCU_CREATE_REQUEST'
export const RCU_CREATE_SUCCESS = '@api/RCU_CREATE_SUCCESS'
export const RCU_CREATE_ERROR = '@api/RCU_CREATE_ERROR'

export const RCU_UPDATE_REQUEST = '@api/RCU_UPDATE_REQUEST'
export const RCU_UPDATE_SUCCESS = '@api/RCU_UPDATE_SUCCESS'
export const RCU_UPDATE_ERROR = '@api/RCU_UPDATE_ERROR'

export const RCU_DELETE_REQUEST = '@api/RCU_DELETE_REQUEST'
export const RCU_DELETE_SUCCESS = '@api/RCU_DELETE_SUCCESS'
export const RCU_DELETE_ERROR = '@api/RCU_DELETE_ERROR'

export const RCU_CLEAN_SUCCESS_REQUEST = '@api/RCU_CLEAN_SUCCESS_REQUEST'
export const RCU_CLEAN_ERROR_REQUEST = '@api/RCU_CLEAN_ERROR_REQUEST'
