import { FormControl, InputLabel, Select, SelectProps } from '@mui/material'
import { Controller, Control, FieldValues } from 'react-hook-form'

interface IProps extends SelectProps {
  children?: React.ReactNode
  control: Control<any>
  'data-e2e'?: any
  defaultValue?: string
  error?: boolean
  id: string
  label?: string
  variant?: 'standard' | 'outlined' | 'filled'
  multipleSelection?: string[] | number[]
  rules?: FieldValues
  onChange?: (value: any) => void
}

const FormSelect = (props: IProps) => {
  const {
    children,
    error,
    id,
    label,
    control,
    defaultValue,
    variant,
    multipleSelection,
    rules,
    style,
    onChange,
  } = props
  const e2e = props['data-e2e'] ?? 'form-select'
  const labelId = `${id}-label`

  return (
    <FormControl error={error} fullWidth size="small" variant={variant} data-e2e={e2e}>
      {label && <InputLabel id={labelId}>{label}</InputLabel>}
      <Controller
        control={control}
        defaultValue={defaultValue}
        name={id}
        render={({ field }) =>
          multipleSelection ? (
            <Select
              id={id}
              data-e2e={e2e + '-select'}
              label={label}
              labelId={labelId}
              {...field}
              renderValue={() => {
                return multipleSelection?.map((item) => item).join(', ')
              }}
            >
              {children}
            </Select>
          ) : (
            <Select
              id={id}
              data-e2e={e2e + '-select'}
              label={label}
              labelId={labelId}
              style={style}
              {...field}
              onChange={(e) => {
                field.onChange(e)
                onChange && onChange(e.target.value)
              }}
            >
              {children}
            </Select>
          )
        }
        rules={rules}
      />
    </FormControl>
  )
}

export default FormSelect
