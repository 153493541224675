import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { IDeviceType } from '../../../../types'
import { getDevicesTypes } from '../../../api/devices/devices'
import { devicesTypesError, devicesTypesSuccess } from '../../actions/devices/actions'

export function* sagaGetDeviceTypes() {
  try {
    const response: IDeviceType[] = yield call(getDevicesTypes)
    yield put(devicesTypesSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(devicesTypesError(error.response.data as unknown))
    } else {
      yield put(devicesTypesError(error))
      throw error
    }
  }
}
