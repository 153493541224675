import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { Grid, Theme } from '@mui/material'

const styles = {
  head: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main,
    height: '60px',
  }),
}

interface IProps {
  'data-e2e'?: string
  title?: string
  titleIcon?: React.ReactElement
  children?: React.ReactNode
}

export const CommonContent = (props: IProps) => {
  return (
    <Card data-e2e={props['data-e2e']}>
      <CardContent sx={styles.head}>
        <div>
          <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
            <Grid item alignItems="center">
              {props.titleIcon}
            </Grid>
            <Grid item>
              <Typography variant="h6" style={{ color: 'white' }}>
                {props.title}
              </Typography>
            </Grid>
          </Grid>
        </div>
      </CardContent>
      <CardContent style={{ padding: '25px' }}>{props.children}</CardContent>
    </Card>
  )
}
