import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { CommonContent } from '../components/CommonContent'
import { AudioFile, Lyrics } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import {
  fileUploadRequest,
  fileDeleteRequest,
  filesCleanErrorRequest,
  filesCleanSuccessRequest,
  musicFilesRequest,
  msgFilesRequest,
} from '../common/store/actions/files/actions'
import { filesStates } from '../common/store/selectors/files'
import FilesTable from './FilesTable'
import { Id } from '../hip/types'

interface FileManagerProps {
  type: string
}

const FileManager = ({ type }: FileManagerProps) => {
  const { hasPrivilege } = usePrivilege()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const { msgFiles, musicFiles, isLoading, error, success } = useSelector(filesStates)
  const dispatch = useDispatch()
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const accept = '.wav,.ogg,.mp3,.flac'

  const [open, setOpen] = useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (type === 'music-files') {
      dispatch(musicFilesRequest({ type: type }))
    }
    if (type === 'msg-files') {
      dispatch(msgFilesRequest({ type: type }))
    }
  }, [type])

  useEffect(() => {
    console.log('isLoading')
  }, [isLoading])

  useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(filesCleanErrorRequest())
    }
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(filesCleanSuccessRequest())
    }
  }, [error, success])

  const handleFileInputChange = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const form = document.querySelector<HTMLFormElement>('#fmFile')
    const formData = new FormData(form as HTMLFormElement)

    dispatch(fileUploadRequest({ type: type, body: formData }))
  }

  const handleConfirmDelete = (id: Id) => {
    dispatch(fileDeleteRequest({ type: type, id: id }))
    handleClose()
  }

  const files = type === 'msg-files' ? msgFiles : musicFiles

  return (
    <CommonContent
      title={t(type)}
      titleIcon={
        type === 'music-files' ? (
          <Lyrics style={{ color: 'white' }} />
        ) : (
          <AudioFile style={{ color: 'white' }} />
        )
      }
      data-e2e={type}
    >
      {(hasPrivilege(Privileges.ADD_MUSIC_FILES) || hasPrivilege(Privileges.ADD_MESSAGE_FILES)) && (
        <Box mb={4}>
          <Button
            onClick={handleFileInputChange}
            data-e2e="add-file-btn"
            variant="contained"
            color="success"
            startIcon={type === 'music-files' ? <Lyrics /> : <AudioFile />}
          >
            {t('files_add')}
          </Button>
        </Box>
      )}
      <form
        style={{ display: 'none' }}
        id="fmFile"
        encType="multipart/form-data"
        action="/files"
        method="post"
        onSubmit={handleSubmit}
      >
        <input
          ref={fileInputRef}
          type="file"
          name="file"
          onChange={() => {
            document.getElementById('upload')?.click()
          }}
          accept={accept}
        />
        <input id="upload" type="submit" value="Upload Now" />
      </form>
      {isLoading && <CircularProgress data-e2e={'is-loading'} />}
      {files?.length ? (
        <FilesTable
          files={files}
          type={type}
          handleConfirmDelete={handleConfirmDelete}
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
        />
      ) : (
        <Typography variant="h6" color="text.secondary" align="center" p={10}>
          No files to show
        </Typography>
      )}
    </CommonContent>
  )
}

export default FileManager
