import { createAction } from '@reduxjs/toolkit'
import {
  RCUS_REQUEST,
  RCUS_SUCCESS,
  RCUS_ERROR,
  RCU_DETAIL_REQUEST,
  RCU_DETAIL_SUCCESS,
  RCU_DETAIL_ERROR,
  RCU_CREATE_ERROR,
  RCU_CREATE_REQUEST,
  RCU_CREATE_SUCCESS,
  RCU_DELETE_ERROR,
  RCU_DELETE_REQUEST,
  RCU_DELETE_SUCCESS,
  RCU_UPDATE_REQUEST,
  RCU_UPDATE_SUCCESS,
  RCU_UPDATE_ERROR,
  AVAILABlE_RCUS_REQUEST,
  AVAILABLE_RCUS_SUCCESS,
  AVAILABLE_RCUS_ERROR,
  RCU_CLEAN_ERROR_REQUEST,
  RCU_CLEAN_SUCCESS_REQUEST,
} from './actionTypes'
import { PaginatedResponse } from '../../../types'
import { Rcu, Id, PageSet, deletedItem } from '../../types'

export const rcusRequest = createAction<PageSet>(RCUS_REQUEST)
export const rcusSuccess = createAction<PaginatedResponse>(RCUS_SUCCESS)
export const rcusError = createAction<any>(RCUS_ERROR)

export const rcuDetailRequest = createAction<Id>(RCU_DETAIL_REQUEST)
export const rcuDetailSuccess = createAction<Rcu>(RCU_DETAIL_SUCCESS)
export const rcuDetailError = createAction<any>(RCU_DETAIL_ERROR)

export const availableRcusRequest = createAction(AVAILABlE_RCUS_REQUEST)
export const availableRcusSuccess = createAction<PaginatedResponse>(AVAILABLE_RCUS_SUCCESS)
export const availableRcusError = createAction<any>(AVAILABLE_RCUS_ERROR)

export const rcuCreateRequest = createAction<Rcu>(RCU_CREATE_REQUEST)
export const rcuCreateSuccess = createAction<Rcu>(RCU_CREATE_SUCCESS)
export const rcuCreateError = createAction<any>(RCU_CREATE_ERROR)

export const rcuUpdateRequest = createAction<Rcu>(RCU_UPDATE_REQUEST)
export const rcuUpdateSuccess = createAction<Rcu>(RCU_UPDATE_SUCCESS)
export const rcuUpdateError = createAction<any>(RCU_UPDATE_ERROR)

export const rcuDeleteRequest = createAction<Id>(RCU_DELETE_REQUEST)
export const rcuDeleteSuccess = createAction<deletedItem>(RCU_DELETE_SUCCESS)
export const rcuDeleteError = createAction<any>(RCU_DELETE_ERROR)

export const rcuCleanSuccessRequest = createAction(RCU_CLEAN_SUCCESS_REQUEST)
export const rcuCleanErrorRequest = createAction(RCU_CLEAN_ERROR_REQUEST)
