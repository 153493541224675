import { call, put } from '@redux-saga/core/effects'
import { IpDevice, VolumeUpdate } from '../../../../types'
import { Action } from '../root'
import axios from 'axios'
import { updateVolume } from '../../../api/devices/volumeUpdate'
import { volumeUpdateError, volumeUpdateSuccess } from '../../actions/devices/actions'

//VOLUME - TEMPORARY DESABLED - pending of hardware command implementation - DON'T DELETE
export function* sagaVolumeUpdate({ payload }: Action<{ body: VolumeUpdate; id: number }>) {
  try {
    const response: IpDevice = yield call(updateVolume, payload)
    yield put(volumeUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(volumeUpdateError(error.response.data as unknown))
    } else {
      yield put(volumeUpdateError(error))
      throw error
    }
  }
}
