import { Typography } from '@mui/material'

interface IProps {
  textStyles: { marginLeft: string } | { marginLeft?: undefined }
  id: number
  name: string
  bed: string
  accessKey: string
  user_name: string
}

const ButtonText = ({ textStyles, id, name, bed, accessKey, user_name }: IProps) => {
  return (
    <div
      style={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        marginLeft: textStyles?.marginLeft,
      }}
    >
      <Typography variant="button" data-e2e={`button_name_${id}`}>
        {name}
      </Typography>
      <Typography variant="caption">{bed === undefined ? null : bed}</Typography>
      {user_name ? (
        <Typography variant="caption">{user_name === undefined ? null : user_name}</Typography>
      ) : (
        <Typography variant="caption">{accessKey === undefined ? null : accessKey}</Typography>
      )}
    </div>
  )
}

export default ButtonText
