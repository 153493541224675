import { Collapse, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import {
  Api,
  Campaign,
  Public,
  Storage,
  SwapCalls,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import { IServiceStatusProps, ServiceStatus } from './ServiceStatus'
import { useState } from 'react'

export const ServicesStatus = () => {
  const [serverOpen, setServerOpen] = useState<boolean>(true)
  const services: IServiceStatusProps[] = [
    { name: 'API', state: 'OK', icon: <Api /> },
    { name: 'Database', state: 'OK', icon: <Storage /> },
    { name: 'MQTT', state: 'OK', icon: <Campaign /> },
    { name: 'SSE', state: 'OK', icon: <SwapCalls /> },
    { name: 'Web', state: 'OK', icon: <Public /> },
  ]
  type IStateCount = { [state: string]: number }
  const reducer = (count: IStateCount, service: IServiceStatusProps): IStateCount => {
    const state = service.state
    count[state] = 1 + (state in count ? count[state] : 0)
    return count
  }
  const stateIcons = [
    ['error', '❌'],
    ['warn', '⚠️'],
    ['OK', '✔️'],
    ['OFF', '⚫'],
  ]
  const stateCount = services.reduce(reducer, {})
  const stateData: string = stateIcons
    .map((txtIc) => [...txtIc, stateCount[txtIc[0]]])
    .filter((txtIcCount) => Number(txtIcCount[2] ?? 0) > 0)
    .map((txtIcCount) => String(txtIcCount[2]) + ' ' + txtIcCount[1])
    .join(', ')

  return (
    <>
      <ListItemButton onClick={() => setServerOpen(!serverOpen)}>
        <ListItemIcon>
          <Storage />
        </ListItemIcon>
        <ListItemText primary={'Services: ' + stateData} />
        {serverOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={serverOpen} timeout="auto" unmountOnExit>
        {services.map((service) => (
          <ServiceStatus key={service.name} {...service} />
        ))}
      </Collapse>
    </>
  )
}
