import { CommonContent } from '../components/CommonContent'
import VerifiedIcon from '@mui/icons-material/Verified'
import { useDispatch, useSelector } from 'react-redux'
import { getLicense } from '../common/store/selectors/license'
import { Box, Button, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { licenseRequest, updateLicenseRequest } from '../common/store/actions/license/action'
import { LicenseIndicator } from './LicenseIndicator'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'

const License = () => {
  const { hasPrivilege } = usePrivilege()
  const dispatch = useDispatch()
  const [licenseHash, setLicenseHash] = useState(null)
  const licenseState = useSelector(getLicense)

  useEffect(() => {
    dispatch(licenseRequest())
  }, [])

  const onClickHandle = (e: React.FormEvent) => {
    e.preventDefault()
    dispatch(updateLicenseRequest({ license: licenseHash }))
  }

  return (
    <CommonContent title="License" titleIcon={<VerifiedIcon style={{ color: 'white' }} />}>
      <LicenseIndicator licenseState={licenseState} />
      {hasPrivilege(Privileges.ADD_LICENSE) && (
        <Box>
          <form onSubmit={onClickHandle}>
            <TextField
              required
              autoFocus
              error={!!licenseState.error}
              helperText={licenseState.error ? 'Invalid license key' : ''}
              margin="dense"
              id="name"
              label="License"
              type="License"
              fullWidth
              variant="standard"
              onChange={(e) => setLicenseHash(e.target.value)}
            />
            <Button variant="contained" type="submit">
              Activate
            </Button>
          </form>
        </Box>
      )}
    </CommonContent>
  )
}

export default License
