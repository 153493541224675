import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './root'
import { taskUpdateError, taskUpdateSuccess } from '../actions/tasks/actions'
import { Tasks } from '../../../types'
import { taskUpdate } from '../../api/tasks/taskUpdate'
import { TaskFormInputs } from '../../../tasks/TaskForm'

export function* sagaTaskUpdate({
  payload,
}: Action<{ id: number; type: string; body: TaskFormInputs }>) {
  //va otro tipo de datos.. es mejor NewTask?
  try {
    const response: Tasks = yield call(taskUpdate, payload)
    yield put(taskUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(taskUpdateError(error.response.data as unknown))
    } else {
      yield put(taskUpdateError(error))
      throw error
    }
  }
}
