import { useTranslation } from 'react-i18next'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Delete } from '@mui/icons-material'
import { ContextExceptions, Tasks } from '../types'
// import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { taskDeleteRequest } from '../common/store/actions/tasks/actions'

interface TasksTablesProps {
  tasks: Tasks[]
  exceptions: ContextExceptions[]
  type: string
}

const TasksTable = ({ tasks, type }: TasksTablesProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const dispatch = useDispatch()
  const handleDelete = (id: number) => {
    const data = { id, type }
    dispatch(taskDeleteRequest(data))
  }

  const curatedMinutes = (minutes: number | undefined) => {
    if (minutes === undefined) {
      return
    }
    return minutes < 10 ? '0' + minutes : minutes.toString()
  }

  // const getRuleContext = (task: Partial<Tasks>) => {
  //   console.log(task)
  //   return task.id
  // }

  return (
    <TableContainer component={Paper}>
      <Divider />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center">{t('name')}</TableCell>
            <TableCell align="center">
              {type === 'exception_rules' ? t('dates') : t('days')}
            </TableCell>
            <TableCell align="center">{type === 'exception_rules' ? null : t('time')}</TableCell>
            {/* <TableCell align="center">{t('context')}</TableCell> */}
            <TableCell align="center"></TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tasks?.map((task: any) => (
            <TableRow key={task.id} data-e2e="tasks-row">
              <TableCell align="center" component="th" scope="row" data-e2e={'task-cell-name'}>
                {task.name}
              </TableCell>
              {type === 'rule_tasks' || type === 'audio_tasks' ? (
                task.day_end ? (
                  <TableCell align="center" data-e2e={'task-cell-days'}>
                    {t('from')} {t(`${task.day_begin}`)} {t('to')} {t(`${task.day_end}`)}
                  </TableCell>
                ) : (
                  <TableCell align="center" data-e2e={'task-cell-day'}>
                    {t('on')} {t(`${task.day_begin}`)}
                  </TableCell>
                )
              ) : null}
              {type === 'exception_rules' ? (
                task.month_end ? (
                  <TableCell align="center" data-e2e={'task-cell-dates'}>
                    {t('from')} {t(`${task.month_begin}`)}/{t(`${task.date_begin}`)} {t('to')}{' '}
                    {t(`${task.month_end}`)}/{t(`${task.date_end}`)}
                  </TableCell>
                ) : (
                  <TableCell align="center" data-e2e={'task-cell-date'}>
                    {t(`${task.month_begin}`)}/{t(`${task.date_begin}`)}
                  </TableCell>
                )
              ) : null}
              {type !== 'exception_rules' ? (
                task.hour_end ? (
                  <TableCell align="center" data-e2e={'task-cell-hours'}>
                    {t('from')} {task.hour_begin}:{curatedMinutes(task.minute_begin)} {t(`hs`)}{' '}
                    {t('to')} {task.hour_end}:{curatedMinutes(task.minute_end)} {t(`hs`)}
                  </TableCell>
                ) : (
                  <TableCell align="center" data-e2e={'task-cell-hour'}>
                    {task.hour_begin}:{curatedMinutes(task.minute_begin)} {t(`hs`)}
                  </TableCell>
                )
              ) : null}
              {/* <TableCell align="center" component="th" scope="row" data-e2e={'task-cell-context'}>
                {getRuleContext(task)}
              </TableCell> */}
              <TableCell align="center">
                <Grid>
                  {/* {hasPrivilege(Privileges.EDIT_TASK) && (
                    <Button
                      component={Link}
                      to={`/tasks/${type}/${task.id}`}
                      data-e2e={`tasks-btn-edit-${task.id}`}
                    >
                      <Edit color="primary" />
                    </Button>
                  )} */}
                </Grid>
              </TableCell>
              <TableCell align="center">
                <Grid>
                  {hasPrivilege(Privileges.DELETE_TASK) && (
                    <Button
                      variant="text"
                      onClick={() => handleDelete(task.id)}
                      data-e2e={`task-btn-dlt-${task.id}`}
                    >
                      <Delete color="error" />
                    </Button>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TasksTable
