import { call, put } from '@redux-saga/core/effects'
import { groupUpdateError, groupUpdateSuccess } from '../actions/groups/actions'
import { Group, NewZone } from '../../api/types'
import axios from 'axios'
import { Action } from './root'
import { updateGroup } from '../../api/groups/groupUpdate'

export function* sagaUpdateGroup({ payload }: Action<{ body: NewZone; id: number }>) {
  try {
    const response: Group = yield call(updateGroup, payload)
    yield put(groupUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(groupUpdateError(error.response.data as unknown))
    } else {
      yield put(groupUpdateError(error))
      throw error
    }
  }
}
