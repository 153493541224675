import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { mqttPublish } from '../../api/mqtt/mqtt'
import { IMqttRequest } from '../../api/types'
import { mqttError, mqttSuccess } from '../actions/mqtt/actions'
import { Action } from './root'

export function* sagaMqttPublish({ payload }: Action<IMqttRequest>) {
  try {
    yield call(mqttPublish, payload)
    yield put(mqttSuccess())
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(mqttError(error.response.data as unknown))
    } else {
      yield put(mqttError(error))
      throw error
    }
  }
}
