import { useSelector } from 'react-redux'
import { getUserProfileState } from '../common/store/selectors/profiles'
import { Privileges } from '../enum'
import { useCallback } from 'react'

const usePrivilege = () => {
  const { profiles } = useSelector(getUserProfileState)

  const hasPrivilege = useCallback(
    (privilege: Privileges) => {
      const allprivileges = profiles
        ? profiles.reduce((acc, profile) => {
            return [...acc, ...profile.privileges]
          }, [])
        : []
      return allprivileges.includes(privilege) || allprivileges.includes(Privileges.ALL)
    },
    [profiles],
  )

  return {
    hasPrivilege,
  }
}

export default usePrivilege
