import { useForm } from 'react-hook-form'
import { CommonContent } from '../components/CommonContent'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { Radio, Done, Delete } from '@mui/icons-material'
import { Button, Grid, TextField } from '@mui/material'
import { radioCreateRequest } from '../common/store/actions/files/actions'

export type RadioFormValues = {
  url: string
  name: string
}

export const RadiosForm = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { register, handleSubmit } = useForm<RadioFormValues>()
  const onSubmit = handleSubmit((data: RadioFormValues) => {
    if (data) {
      dispatch(radioCreateRequest(data))
      navigate('/radios')
    }
  })

  return (
    <CommonContent title={t('radios')} titleIcon={<Radio style={{ color: 'white' }} />}>
      <form onSubmit={onSubmit}>
        <TextField
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="name"
          label={t('name')}
          id="outlined-multiline-flexible"
          variant="outlined"
          {...register('name')}
        />

        <TextField
          style={{ paddingBottom: 20 }}
          fullWidth
          required
          type="text"
          name="url"
          label={t('url')}
          id="outlined-start-adornment"
          variant="outlined"
          {...register('url')}
        />
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              onClick={() => {
                navigate('/radios')
              }}
              variant="outlined"
              color="secondary"
              startIcon={<Delete />}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" type="submit" startIcon={<Done />}>
              {t('add')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </CommonContent>
  )
}
