import axios from 'axios'
import { put, call } from 'redux-saga/effects'
import { Action } from '../root'
import { fileUploadError, fileUploadSuccess } from '../../actions/files/actions'
import { uploadFile } from '../../../api/files/uploadFile'
import { IFile } from '../../../../types'

export function* sagaUploadFile({ payload }: Action<{ body: FormData; type: string }>) {
  const { type } = payload
  try {
    const response: IFile = yield call(uploadFile, payload)
    yield put(fileUploadSuccess({ file: response, type: type }))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(fileUploadError(error.response.data as unknown))
    } else {
      yield put(fileUploadError(error))
      throw error
    }
  }
}

//Descomentar junto con lo de api para enviar progreso de carga al front,
//agregar un estado loading:number al reducer
// type UploadProgress = {
//   total: number
//   loaded: number
// }

// type CreateUploaderResult = [Promise<any>, EventChannel<number>]

// type ChanEventType = number

// function delay(delay: number) {
//   return new Promise((res) => setTimeout(res, delay))
// }

// function createUploader(body: FormData, type: string): CreateUploaderResult {
//   let emit: (input: number | END) => void
//   const chan: EventChannel<ChanEventType> = eventChannel((emitter) => {
//     emit = emitter
//     return () => {}
//   })

//   const uploadProgressCb = ({ total, loaded }: UploadProgress) => {
//     const percentage = Math.round((loaded * 100) / total)
//     emit(percentage)
//     if (percentage === 100) emit(END)
//   }

//   const uploadPromise = uploadFile(body, type, uploadProgressCb)
//   return [uploadPromise, chan]
// }

// function* uploadProgressWatcher(chan: EventChannel<ChanEventType>) {
//   while (true) {
//     // eslint-disable-line no-constant-condition
//     const progress: number = yield take(chan)
//     yield put(setUploadProgress(progress))
//   }
// }

// export function* sagaUploadFile({
//   payload,
// }: Action<{ body: FormData; type: string }>): Generator<any, IFile, any> {
//   const { type, body } = payload

//   try {
//     const [uploadPromise, chan] = yield call(createUploader, body, type)
//     yield fork(uploadProgressWatcher, chan)
//     const res: AxiosResponse = yield call(() => uploadPromise)
//     yield call(delay, 1000)
//     yield put(fileUploadSuccess({ file: res.data, type: type }))
//     return res.data
//   } catch (e) {
//     yield put(fileUploadError(e))
//     throw e
//   }
// }
