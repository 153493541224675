import { call, put } from '@redux-saga/core/effects'
import { Action } from './root'
import axios from 'axios'
import { taskDelete } from '../../api/tasks/taskDelete'
import { taskDeleteSuccess, taskDeleteError } from '../actions/tasks/actions'
import { deletedItem } from '../../../hip/types'

export function* sagaTaskDelete({ payload }: Action<{ id: number; type: string }>) {
  try {
    const response: deletedItem = yield call(taskDelete, payload)
    yield put(taskDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(taskDeleteError(error.response.data as unknown))
    } else {
      yield put(taskDeleteError(error))
      throw error
    }
  }
}
