import { useState, useEffect } from 'react'
import { Box, Theme, Typography } from '@mui/material'

const styles = {
  header: (theme: Theme) => ({
    borderRadius: '5%',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: 3,
    display: 'flex',
    justifyContent: 'center',
    height: '35%',
    mt: '5px',
  }),
}

const Clock = () => {
  const [date, setDate] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => {
      setDate(new Date())
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  const formatDate = date.toLocaleString()

  return (
    <Box sx={styles.header}>
      <Typography alignSelf={'center'}>{formatDate}</Typography>
    </Box>
  )
}

export default Clock
