import { createReducer } from '@reduxjs/toolkit'
import { mqttError, mqttRequest, mqttSuccess } from '../actions/mqtt/actions'

interface MqttState {
  isLoading: boolean
  error: string
}

export const initialState = (): MqttState => ({
  isLoading: false,
  error: null,
})

export const mqttReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(mqttRequest, (state) => {
      state.isLoading = true
    })
    .addCase(mqttSuccess, (state) => {
      state.isLoading = false
    })
    .addCase(mqttError, (state, { payload }) => {
      console.error('mqttReducer - mqttError', payload)
      state.error = JSON.stringify(payload)
      state.isLoading = false
    })
})
