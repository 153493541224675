import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../root'
import { fileDeleteError, fileDeleteSuccess } from '../../actions/files/actions'
import { deleteFile } from '../../../api/files/deleteFile'
import { Id } from '../../../../hip/types'

export function* sagaDeleteFile({ payload }: Action<{ id: Id; type: string }>) {
  const { type } = payload
  try {
    const response: { id: Id } = yield call(deleteFile, payload)
    const { id } = response
    yield put(fileDeleteSuccess({ id: id, type: type }))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(fileDeleteError(error.response.data as unknown))
    } else {
      yield put(fileDeleteError(error))
      throw error
    }
  }
}
