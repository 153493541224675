import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { Schedule } from '@mui/icons-material'
import { Box, Button, Grid } from '@mui/material'
import { CommonContent } from '../components/CommonContent'

interface TaskProps {
  types: string[]
}

const Tasks = ({ types }: TaskProps) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { hasPrivilege } = usePrivilege()

  const handleClick = (type: string) => {
    navigate(`/tasks/${type}`)
  }

  return (
    <Grid>
      <Grid item>
        <CommonContent
          title={t('tasks')}
          titleIcon={<Schedule style={{ color: 'white' }} data-e2e={`tasks-title`} />}
        >
          {hasPrivilege(Privileges.VIEW_TASKS) &&
            types.map(
              (type) =>
                type !== 'exception_rules' && (
                  <Box mb={4} key={type}>
                    <Button
                      data-e2e={`tasks-btn-${type}`}
                      onClick={() => handleClick(type)}
                      variant="contained"
                      startIcon={<Schedule />}
                    >
                      {t(type)}
                    </Button>
                  </Box>
                ),
            )}
        </CommonContent>
      </Grid>
      <Grid item>
        <CommonContent
          title={t('exceptions')}
          titleIcon={<Schedule style={{ color: 'white' }} data-e2e={`tasks-exceptions-title`} />}
        >
          {hasPrivilege(Privileges.VIEW_TASKS) &&
            types.map(
              (type) =>
                type === 'exception_rules' && (
                  <Box mb={4} key={type}>
                    <Button
                      data-e2e={`tasks-btn-${type}`}
                      onClick={() => handleClick(type)}
                      variant="contained"
                      startIcon={<Schedule />}
                    >
                      {t(type)}
                    </Button>
                  </Box>
                ),
            )}
        </CommonContent>
      </Grid>
    </Grid>
  )
}

export default Tasks
