import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { CommonContent } from '../components/CommonContent'
import { Done, Schedule, Cancel } from '@mui/icons-material'
import { Box, Button, FormControl, Grid, MenuItem, TextField } from '@mui/material'
import FormSelect from '../components/FormSelect'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { tasksStates } from '../common/store/selectors/tasks'
import { groupsStates } from '../common/store/selectors/groups'
import { devicesState } from '../common/store/selectors/devices'
import { messagessStates } from '../common/store/selectors/messages'
import { devicesRequest } from '../common/store/actions/devices/actions'
import { groupsRequest } from '../common/store/actions/groups/actions'
import { messagesRequest } from '../common/store/actions/messages/action'
import { IpDevice, NewTask } from '../types'
import {
  taskCreateRequest,
  taskDetailRequest,
  taskUpdateRequest,
} from '../common/store/actions/tasks/actions'
import { dayNumber, daysOfWeek } from './TasksUtils'
import { getUserInfo } from '../common/store/selectors/user'
import { Dayjs } from 'dayjs'

export interface TaskFormInputs {
  name: string
  day_begin: string
  day_end?: string
  time_begin: Dayjs
  time_end?: Dayjs
  speaker_id: string
  group: number
  message: number
}

const TaskForm = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { taskId, type } = useParams()
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const { task } = useSelector(tasksStates)
  const { groups } = useSelector(groupsStates)
  const { messages } = useSelector(messagessStates)
  const { devices } = useSelector(devicesState)
  const speakers = devices.items as IpDevice[]
  const { control, handleSubmit, getValues, reset } = useForm<TaskFormInputs>({})
  const { id } = useSelector(getUserInfo)
  // const [daysError, setDaysError] = useState<boolean>(false)
  const [onMonth, setOnMonth] = useState<Dayjs | null>(null)

  useEffect(() => {
    if (taskId !== 'add') {
      const data = { taskId, type }
      dispatch(taskDetailRequest(data))
      setIsEdit(true)
    }
    const req = {
      pageSet: { limit: '200', page: '1' },
      type: 'speaker',
    }
    dispatch(devicesRequest(req))
    dispatch(groupsRequest())
    dispatch(messagesRequest())
  }, [taskId])

  const invalidTime = (value: any) => {
    if (Number.isNaN(value)) {
      console.log('Some hour/minute value is invalid.')
      return true
    }
  }

  const invalidDays = (day_begin: string, day_end: string) => {
    if (type === 'rule_tasks' && day_end !== '') {
      const first = dayNumber(day_begin)
      const second = dayNumber(day_end)
      if (first > second || first === second) return true
    }
  }

  const invalidMonth = (date: Dayjs) => {
    if (onMonth === null) {
      return false
    }
    return date.get('month') !== onMonth.get('month')
  }

  const handleChange = (newValue: any) => {
    setOnMonth(newValue)
  }

  const submitForm = (data: TaskFormInputs) => {
    if (isEdit) {
      if (task) {
        const updatedData: any = {
          name: data.name ? data.name : task.name,
          day_begin: data.day_begin ? data.day_begin : task.day_begin,
          day_end: data.day_end ? data.day_end : task.day_end,
          // action: task.action,
          // hour_begin: begin_selected.getHours(),
          // minute_begin: begin_selected.getMinutes(),
          // hour_end: end_selected.getHours(),
          // minute_end: end_selected.getMinutes(),
        }
        const req = {
          id: taskId,
          type: type,
          body: updatedData,
        }
        dispatch(taskUpdateRequest(req))
      }
    } else {
      if (data) {
        const { name, day_begin, day_end, speaker_id, group, message, time_begin, time_end } = data

        if (invalidDays(day_begin, day_end)) return
        const date = new Date().toISOString().replace('Z', '')
        const end_selected = time_end ? time_end : null

        const hour_time_begin = time_begin.hour()
        const min_time_begin = time_begin.minute()

        let newTask: NewTask = null

        if (type === 'rule_tasks') {
          const hour_time_end = time_end.hour()
          const min_time_end = time_end.minute()

          if (invalidTime(hour_time_begin)) return
          if (invalidTime(min_time_begin)) return
          if (invalidTime(hour_time_end)) return
          if (invalidTime(min_time_end)) return

          newTask = {
            name,
            from_date: date,
            day_begin,
            day_end: day_end ? day_end : null,
            hour_begin: hour_time_begin,
            minute_begin: min_time_begin,
            hour_end: hour_time_end,
            minute_end: min_time_end,
            action: JSON.stringify({
              type: 'RelayTask',
              ip_device_id: speaker_id,
              relays: [1],
              value: 1,
            }), //CRON TO DO agregar input de relays por placa en form device y relevar en un selector acá
            sort_index: 3,
            context: speaker_id, //Se podría generar un contexto más específico por MAC+relay
          }
        }
        if (type === 'audio_tasks') {
          if (invalidTime(hour_time_begin)) return
          if (invalidTime(min_time_begin)) return

          newTask = {
            name,
            from_date: date,
            day_begin,
            day_end,
            hour_begin: hour_time_begin,
            minute_begin: min_time_begin,
            action: JSON.stringify({
              type: 'MessageTask',
              group_id: group,
              message_id: message,
              user_id: id,
            }),
            sort_index: 2,
            context: group.toString(),
          }
        }
        if (type === 'exception_rules') {
          newTask = {
            name,
            date_begin: time_begin.date(),
            month_begin: time_begin.month() + 1,
            date_end: end_selected ? end_selected?.date() : null,
            month_end: end_selected?.month() + 1,
          }
        }
        const req = {
          body: newTask,
          type: type,
        }
        dispatch(taskCreateRequest(req))
      }
    }
    navigate(`/tasks/${type}`)
    reset({
      name: '',
      day_begin: '',
      day_end: '',
      speaker_id: '',
      time_begin: null,
      time_end: null,
      group: null,
      message: null,
    })
    setOnMonth(null)
  }

  const renderSelectOptions = (items: string[]) => {
    return items.map((item: string, index: number) => (
      <MenuItem key={index} value={item}>
        {t(item)}
      </MenuItem>
    ))
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <CommonContent
        title={isEdit ? t(`edit_task`) : t(`add_${type}`)}
        titleIcon={<Schedule style={{ color: 'white' }} />}
        data-e2e={isEdit ? 'title-edit-task' : 'title-add-task'}
      >
        <FormControl fullWidth>
          <Controller
            name="name"
            control={control}
            defaultValue={isEdit ? task?.name : ''}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
              validate: () => {
                const name = getValues('name')
                if (name.trim() !== name) return t('check_empty')
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                data-e2e="tasks-name-fld"
                label={isEdit ? t('update_name') : t('name')}
                variant="outlined"
                helperText={error ? error.message : null}
                error={!!error}
                size="small"
                onChange={onChange}
                value={value}
                fullWidth
              />
            )}
          />
          {type === 'rule_tasks' || type === 'audio_tasks' ? (
            <>
              <Box mt={4}>
                <FormSelect
                  data-e2e="tasks-day"
                  control={control}
                  id="day_begin"
                  label={t('day_begin')}
                  size="small"
                  defaultValue={isEdit ? t(task?.day_begin) : ''}
                  fullWidth
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                  }}
                >
                  {renderSelectOptions(daysOfWeek)}
                </FormSelect>
              </Box>
              <Box mt={4}>
                <FormSelect
                  data-e2e="tasks-day-end"
                  control={control}
                  id="day_end"
                  label={t('day_end')}
                  size="small"
                  defaultValue={isEdit ? t(task?.day_end) : ''}
                  fullWidth
                  // rules={{
                  //   validate: () => {
                  //     const day_begin = getValues('day_begin')
                  //     const day_end = getValues('day_end')
                  //     if (invalidDays(day_begin, day_end)) return t('days_range')
                  //   },
                  // }} No funciona porque hay que restructurar los FormSelect. Validación en el submit
                >
                  {renderSelectOptions(daysOfWeek)}
                </FormSelect>
              </Box>
              <Box mt={4} data-e2e="TimePickerBegin">
                <Controller
                  name="time_begin"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <TimePicker
                      ampm={false}
                      label={type === 'rule_tasks' ? t('hour_begin') : t('time')}
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
              </Box>
            </>
          ) : null}
          {type === 'rule_tasks' ? (
            <Box mt={4} data-e2e="TimePickerEnd">
              <Controller
                name="time_end"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('required'),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <TimePicker
                    ampm={false}
                    label={t('hour_end')}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </Box>
          ) : null}
          {type === 'exception_rules' ? (
            <>
              <Box mt={4}>
                <Controller
                  name="time_begin"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      format="LL"
                      label={t('holiday')}
                      value={value}
                      onChange={(newValue: any) => {
                        onChange(newValue)
                        handleChange(newValue)
                      }}
                      slotProps={{
                        textField: {
                          helperText: t('only_day'),
                        },
                      }}
                    />
                  )}
                />
              </Box>
              <Box mt={4}>
                <Controller
                  name="time_end"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DatePicker
                      format="LL"
                      label={t('holiday_end')}
                      value={value}
                      onChange={onChange}
                      shouldDisableMonth={invalidMonth}
                      slotProps={{
                        textField: {
                          helperText: t('same_month'),
                        },
                      }}
                    />
                  )}
                />
              </Box>
            </>
          ) : null}
          {type === 'rule_tasks' && (
            <Box mt={4}>
              <FormSelect
                data-e2e="tasks-spkr"
                control={control}
                id="speaker_id"
                label={t('speakers')}
                size="small"
                defaultValue={isEdit ? t(task?.day_end) : ''}
                fullWidth
                rules={{
                  required: {
                    value: true,
                    message: t('required'),
                  },
                }}
              >
                {speakers.map((speaker) => (
                  <MenuItem key={speaker.id} value={speaker.device_id}>
                    {speaker.description}
                  </MenuItem>
                ))}
              </FormSelect>
            </Box>
          )}
          {type === 'audio_tasks' && (
            <>
              <Box mt={4}>
                <FormSelect
                  control={control}
                  id="group"
                  label={t('group')}
                  size="small"
                  defaultValue={isEdit ? t(task?.day_end) : ''}
                  fullWidth
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                  }}
                >
                  {groups.map((group) => (
                    <MenuItem key={group.id} value={group.id}>
                      {group.name}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Box>
              <Box mt={4}>
                <FormSelect
                  control={control}
                  id="message"
                  label={t('messages')}
                  size="small"
                  defaultValue={isEdit ? t(task?.day_end) : ''}
                  fullWidth
                  rules={{
                    required: {
                      value: true,
                      message: t('required'),
                    },
                  }}
                >
                  {messages.map((message) => (
                    <MenuItem key={message.id} value={message.id}>
                      {message.name}
                    </MenuItem>
                  ))}
                </FormSelect>
              </Box>
            </>
          )}
          <Grid container direction="row" justifyContent="flex-end" spacing={2} mt={3}>
            <Grid item>
              <Button
                variant="contained"
                color="inherit"
                onClick={() => navigate(`/tasks/${type}`)}
                startIcon={<Cancel />}
              >
                {t('cancel')}
              </Button>
            </Grid>
            <Grid item>
              {hasPrivilege(Privileges.ADD_TASKS) && (
                <Button
                  data-e2e="btn-add-task"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit(submitForm)()}
                  startIcon={<Done />}
                >
                  {isEdit ? t('save') : t('add')}
                </Button>
              )}
            </Grid>
          </Grid>
        </FormControl>
      </CommonContent>
    </LocalizationProvider>
  )
}

export default TaskForm
