import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import { CircularProgress, Grid } from '@mui/material'
import { SettingsRemote } from '@mui/icons-material'
import { CommonContent } from '../../components/CommonContent'
import DetailContainer from '../components/DetailContainer'
import RcusForm from './RcusForm'
import { rcuDetailRequest, rcuCleanErrorRequest } from '../actions/rcus/actions'
import { rcusStates } from '../store/selectors/rcus'
import { Rcu } from '../types'
import { useSnackbar } from 'notistack'

const RcuDetail = () => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const { enqueueSnackbar } = useSnackbar()
  const { id } = useParams()
  const dispatch = useDispatch()
  const { rcu, error, isLoading } = useSelector(rcusStates)
  const initialValues: Rcu = {
    id: Number(rcu.id),
    device_id: rcu.device_id,
    description: rcu.description,
  }

  useEffect(() => {
    dispatch(rcuDetailRequest(Number(id)))
  }, [])

  useEffect(() => {
    if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(rcuCleanErrorRequest())
    }
  }, [error])

  const objectEntries = Object.entries(rcu)

  return (
    <CommonContent
      title={t('device_detail')}
      titleIcon={<SettingsRemote style={{ color: 'white' }} />}
    >
      {isLoading ? (
        <CircularProgress style={{ margin: 'auto' }} />
      ) : (
        <>
          <Grid container direction={'column'} alignContent={'center'} spacing={2} mb={4}>
            <Grid item xs={6}>
              <DetailContainer
                title={'device_detail'}
                object={objectEntries}
                data-e2e="rcus-item"
              />
            </Grid>
          </Grid>
          {hasPrivilege(Privileges.EDIT_DEVICE) && <RcusForm initialValues={initialValues} />}
        </>
      )}
    </CommonContent>
  )
}

export default RcuDetail
