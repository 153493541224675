import { call, put } from '@redux-saga/core/effects'
import { Action } from './../root'
import { PaginatedResponse } from '../../../../types'
import axios from 'axios'
import { ipdevicesError, ipdevicesSuccess } from '../../actions/devices/actions'
import { getIpDevices } from '../../../api/devices/ipdevices'
import { PageSet } from '../../../../hip/types'

export function* sagaGetIPDevices({ payload }: Action<PageSet>) {
  try {
    const response: PaginatedResponse = yield call(getIpDevices, payload)
    yield put(ipdevicesSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(ipdevicesError(error.response.data as unknown))
    } else {
      yield put(ipdevicesError(error))
      throw error
    }
  }
}
