import { Menu, MenuItem } from '@mui/material'
import React, { FC, useEffect } from 'react'

export interface MenuItemActions {
  label: string
  onClick: () => void
}

interface IProps {
  toClose?: boolean
  menu?: MenuItemActions[]
}

const ContextMenu: FC<IProps> = ({ children, toClose, menu }) => {
  const [contextMenu, setContextMenu] = React.useState(null)
  const handleContextMenu = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault()
    setContextMenu(
      contextMenu === null
        ? {
            mouseX: event.clientX + 2,
            mouseY: event.clientY - 6,
          }
        : null,
    )
  }

  const handleClose = () => {
    setContextMenu(null)
  }

  useEffect(() => {
    if (toClose) {
      setContextMenu(null)
    }
  }, [toClose])

  return (
    <>
      <div onContextMenu={menu ? handleContextMenu : undefined}>{children}</div>
      {contextMenu && menu && (
        <Menu
          open={contextMenu !== null}
          onClose={handleClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null ? { top: contextMenu.mouseY, left: contextMenu.mouseX } : undefined
          }
        >
          {menu?.map((item, index) => (
            <MenuItem
              data-e2e={`menu-item-${index}`}
              key={index}
              onClick={() => {
                item.onClick()
                handleClose()
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  )
}

export default ContextMenu
