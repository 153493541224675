import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { roomDetailError, roomDetailSuccess } from '../../../actions/rooms/actions'
import { Room, roomId } from '../../../types'
import { getRoomDetail } from '../../../api/roomDetail'

export function* sagaGetRoomDetail({ payload }: Action<roomId>) {
  try {
    const response: Room = yield call(getRoomDetail, payload)
    yield put(roomDetailSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(roomDetailError(error.response.data as unknown))
    } else {
      yield put(roomDetailError(error))
      throw error
    }
  }
}
