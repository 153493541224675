import { createAction } from '@reduxjs/toolkit'
import {
  RADIO_REQUEST,
  RADIO_SUCCESS,
  RADIO_ERROR,
  RADIO_DELETE_REQUEST,
  RADIO_DELETE_SUCCESS,
  RADIO_DELETE_ERROR,
  MSG_FILES_SUCCESS,
  MUSIC_FILES_SUCCESS,
  FILE_UPLOAD_REQUEST,
  FILE_UPLOAD_SUCCESS,
  FILE_UPLOAD_ERROR,
  FILES_CLEAN_ERROR_REQUEST,
  FILES_CLEAN_SUCCESS_REQUEST,
  FILE_DELETE_REQUEST,
  FILE_DELETE_SUCCESS,
  FILE_DELETE_ERROR,
  PLAY_FILE_ERROR,
  STOP_FILE_REQUEST,
  STOP_FILE_ERROR,
  PLAY_MSG_FILE_REQUEST,
  PLAY_MUSIC_FILE_REQUEST,
  RADIO_CREATE_REQUEST,
  RADIO_CREATE_SUCCESS,
  RADIO_CREATE_ERROR,
  MSG_FILES_REQUEST,
  MUSIC_FILES_REQUEST,
  FILES_ERROR,
} from './actionTypes'
import { IFile, Radio } from '../../../../types'
import { Id } from '../../../../hip/types'
import { RadioFormValues } from '../../../../radio_manager/RadiosForm'

//Radio
export const radiosRequest = createAction(RADIO_REQUEST)
export const radiosSuccess = createAction<Radio[]>(RADIO_SUCCESS)
export const radiosError = createAction<any>(RADIO_ERROR)

export const radioCreateRequest = createAction<RadioFormValues>(RADIO_CREATE_REQUEST)
export const radioCreateSuccess = createAction<Radio>(RADIO_CREATE_SUCCESS)
export const radioCreateError = createAction<any>(RADIO_CREATE_ERROR)

export const radioDeleteRequest = createAction<{ id: number }>(RADIO_DELETE_REQUEST)
export const radioDeleteSuccess = createAction<{ id: string }>(RADIO_DELETE_SUCCESS)
export const radioDeleteError = createAction<any>(RADIO_DELETE_ERROR)

//Music & Message Files
export const msgFilesRequest = createAction<{ type: string }>(MSG_FILES_REQUEST)
export const musicFilesRequest = createAction<{ type: string }>(MUSIC_FILES_REQUEST)

export const msgFilesSuccess = createAction<IFile[]>(MSG_FILES_SUCCESS)
export const musicFilesSuccess = createAction<IFile[]>(MUSIC_FILES_SUCCESS)

export const filesError = createAction<any>(FILES_ERROR)

export const fileUploadRequest = createAction<{
  body: FormData
  type: string
}>(FILE_UPLOAD_REQUEST)
export const fileUploadSuccess = createAction<{ file: IFile; type: string }>(FILE_UPLOAD_SUCCESS)
export const fileUploadError = createAction<any>(FILE_UPLOAD_ERROR)

// export const setUploadProgress = createAction<number>(SET_UPLOAD_PROGRESS)

export const playMsgFileRequest = createAction<{ file: string }>(PLAY_MSG_FILE_REQUEST)
export const playMusicFileRequest = createAction<{ group: string; file: string }>(
  PLAY_MUSIC_FILE_REQUEST,
)
export const playFileError = createAction<any>(PLAY_FILE_ERROR)

export const stopFileRequest = createAction<{ process: string }>(STOP_FILE_REQUEST)
export const stopFileError = createAction<any>(STOP_FILE_ERROR)

export const fileDeleteRequest = createAction<{ id: Id; type: string }>(FILE_DELETE_REQUEST)
export const fileDeleteSuccess = createAction<{ id: Id; type: string }>(FILE_DELETE_SUCCESS)
export const fileDeleteError = createAction<any>(FILE_DELETE_ERROR)

export const filesCleanSuccessRequest = createAction(FILES_CLEAN_SUCCESS_REQUEST)
export const filesCleanErrorRequest = createAction(FILES_CLEAN_ERROR_REQUEST)
