import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './root'
import { taskCreateSuccess, taskCreateError } from '../actions/tasks/actions'
import { NewTask, Tasks } from '../../../types'
import { taskCreate } from '../../api/tasks/taskCreate'

export function* sagaTaskCreate({ payload }: Action<{ body: NewTask; type: string }>) {
  try {
    const response: Tasks = yield call(taskCreate, payload)
    yield put(taskCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(taskCreateError(error.response.data as unknown))
    } else {
      yield put(taskCreateError(error))
      throw error
    }
  }
}
