import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { getFiles } from '../../../api/files/getFiles'
import { filesError, msgFilesSuccess } from '../../actions/files/actions'
import { Action } from '../root'
import { IFile } from '../../../../types'

export function* sagaGetMsgFiles({ payload }: Action<{ type: string }>) {
  const type = payload.type
  try {
    const response: IFile[] = yield call(getFiles, type)
    yield put(msgFilesSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(filesError(error.response.data as unknown))
    } else {
      yield put(filesError(error))
      throw error
    }
  }
}
