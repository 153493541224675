import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { getLicense, updateLicense } from '../../api/license/license'
import { ILicenseRequest, ILicenseResponse } from '../../api/types'
import {
  licenseError,
  licenseSuccess,
  updateLicenseError,
  updateLicenseSuccess,
} from '../actions/license/action'
import { Action } from './root'

export function* sagaGetLicense() {
  try {
    const response: ILicenseResponse = yield call(getLicense)
    yield put(licenseSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(licenseError(error.response.data as unknown))
    } else {
      yield put(licenseError(error))
      throw error
    }
  }
}

export function* sagaUpdateLicense({ payload }: Action<ILicenseRequest>) {
  try {
    const response: ILicenseResponse = yield call(updateLicense, payload)
    yield put(updateLicenseSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(updateLicenseError(error.response.data as unknown))
    } else {
      yield put(updateLicenseError(error))
      throw error
    }
  }
}
