import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { roomUpdateError, roomUpdateSuccess } from '../../../actions/rooms/actions'
import { Action } from '../../../../common/store/sagas/root'
import { Room, UpdatedRoomdData } from '../../../types'
import { roomUpdate } from '../../../api/roomUpdate'

export function* sagaRoomUpdate({ payload }: Action<UpdatedRoomdData>) {
  try {
    const response: Room = yield call(roomUpdate, payload)
    yield put(roomUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(roomUpdateError(error.response.data as unknown))
    } else {
      yield put(roomUpdateError(error))
      throw error
    }
  }
}
