import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './../root'
import { deviceDeleteError, deviceDeleteSuccess } from '../../actions/devices/actions'
import { deleteDevice } from '../../../api/devices/deviceDelete'
import { deletedItem } from '../../../../hip/types'

export function* sagaDeleteDevice({ payload }: Action<number>) {
  try {
    const response: deletedItem = yield call(deleteDevice, payload)
    yield put(deviceDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(deviceDeleteError(error.response.data as unknown))
    } else {
      yield put(deviceDeleteError(error))
      throw error
    }
  }
}
