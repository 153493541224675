//Radio
export const RADIO_REQUEST = '@api/RADIO_REQUEST'
export const RADIO_SUCCESS = '@api/RADIO_SUCCESS'
export const RADIO_ERROR = '@api/RADIO_ERROR'

export const RADIO_CREATE_REQUEST = '@api/RADIO_CREATE_REQUEST'
export const RADIO_CREATE_SUCCESS = '@api/RADIO_CREATE_SUCCESS'
export const RADIO_CREATE_ERROR = '@api/RADIO_CREATE_ERROR'

export const RADIO_DELETE_REQUEST = '@api/RADIO_DELETE_REQUEST'
export const RADIO_DELETE_SUCCESS = '@api/RADIO_DELETE_SUCCESS'
export const RADIO_DELETE_ERROR = '@api/RADIO_DELETE_ERROR'

//Music & Messages Files
export const MSG_FILES_REQUEST = '@api/MSG_FILES_REQUEST'
export const MUSIC_FILES_REQUEST = '@api/MUSIC_FILES_REQUEST'
export const MSG_FILES_SUCCESS = '@api/MSG_FILES_SUCCESS'
export const MUSIC_FILES_SUCCESS = '@api/MUSIC_FILES_SUCCESS'
export const FILES_ERROR = '@api/FILES_ERROR'

export const FILE_UPLOAD_REQUEST = '@api/FILE_UPLOAD_REQUEST'
export const FILE_UPLOAD_SUCCESS = '@api/FILE_UPLOAD_SUCCESS'
export const FILE_UPLOAD_ERROR = '@api/FILE_UPLOAD_ERROR'

// export const SET_UPLOAD_PROGRESS = '@api/SET_UPLOAD_PROGRESS'

export const PLAY_MSG_FILE_REQUEST = '@api/PLAY_MSG_FILE_REQUEST'
export const PLAY_MUSIC_FILE_REQUEST = '@api/PLAY_MUSIC_FILE_REQUEST'
export const PLAY_FILE_ERROR = '@api/PLAY_FILE_ERROR'

export const STOP_FILE_REQUEST = '@api/STOP_FILE_REQUEST'
export const STOP_FILE_ERROR = '@api/STOP_FILE_ERROR'

export const FILE_DELETE_REQUEST = '@api/FILE_DELETE_REQUEST'
export const FILE_DELETE_SUCCESS = '@api/FILE_DELETE_SUCCESS'
export const FILE_DELETE_ERROR = '@api/FILE_DELETE_ERROR'

export const FILES_CLEAN_SUCCESS_REQUEST = '@api/FILES_CLEAN_SUCCESS_REQUEST'
export const FILES_CLEAN_ERROR_REQUEST = '@api/FILES_CLEAN_ERROR_REQUEST'
