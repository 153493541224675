import { getGroups } from '../../api/groups/groupsGetter'
import { call, put } from '@redux-saga/core/effects'
import { groupsError, groupsSuccess } from '../actions/groups/actions'
import { Group } from '../../api/types'
import axios from 'axios'

export function* sagaGetGroups() {
  try {
    const response: Group[] = yield call(getGroups)
    yield put(groupsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(groupsError(error.response.data as unknown))
    } else {
      yield put(groupsError(error))
      throw error
    }
  }
}
