import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

export interface IButtonListItemProps {
  title: string
  children: JSX.Element
  'data-e2e'?: string
  onClick?: () => void
}

export default function ButtonListItem(props: IButtonListItemProps) {
  return (
    <ListItem button onClick={props.onClick} data-e2e={props['data-e2e']}>
      <ListItemIcon>{props.children}</ListItemIcon>
      <ListItemText primary={props.title} />
    </ListItem>
  )
}
