import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { getUserProfile } from '../../api/profile/profile'
import { IUserProfile, IUserProfileRequest } from '../../api/types'
import { userProfileError, userProfileSuccess } from '../actions/profile/actions'
import { Action } from './root'

export function* sagaUserProfile({ payload }: Action<IUserProfileRequest>) {
  try {
    const response: IUserProfile = yield call(getUserProfile, payload.user_id)
    yield put(userProfileSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(userProfileError(error.response.data as unknown))
    } else {
      yield put(userProfileError(error))
      throw error
    }
  }
}
