import { createAction } from '@reduxjs/toolkit'
import {
  TASKS_REQUEST,
  TASKS_SUCCESS,
  TASKS_ERROR,
  EXCEPTION_REQUEST,
  EXCEPTION_SUCCESS,
  EXCEPTION_ERROR,
  TASK_CREATE_REQUEST,
  TASK_CREATE_SUCCESS,
  TASK_CREATE_ERROR,
  TASK_CLEAN_SUCCESS_REQUEST,
  TASK_CLEAN_ERROR_REQUEST,
  TASK_DELETE_REQUEST,
  TASK_DELETE_SUCCESS,
  TASK_DELETE_ERROR,
  TASK_DETAIL_REQUEST,
  TASK_DETAIL_SUCCESS,
  TASK_DETAIL_ERROR,
  TASK_UPDATE_REQUEST,
  TASK_UPDATE_SUCCESS,
  TASK_UPDATE_ERROR,
  EXCEPTIONS_CREATE_REQUEST,
  EXCEPTIONS_CREATE_SUCCESS,
  EXCEPTIONS_CREATE_ERROR,
} from './actionTypes'
import { PageSet, deletedItem } from '../../../../hip/types'
import {
  ContextExceptions,
  ExceptionData,
  NewTask,
  PaginatedResponse,
  Tasks,
} from '../../../../types'
import { TaskFormInputs } from '../../../../tasks/TaskForm'

export const tasksRequest = createAction<{ pageSet: PageSet; type: string }>(TASKS_REQUEST)
export const tasksSuccess = createAction<PaginatedResponse>(TASKS_SUCCESS)
export const tasksError = createAction<any>(TASKS_ERROR)

export const exceptionsRequest = createAction(EXCEPTION_REQUEST)
export const exceptionsSuccess = createAction<ContextExceptions[]>(EXCEPTION_SUCCESS)
export const exceptionsError = createAction<any>(EXCEPTION_ERROR)

export const taskDetailRequest = createAction<{ taskId: string; type: string }>(TASK_DETAIL_REQUEST)
export const taskDetailSuccess = createAction<Tasks>(TASK_DETAIL_SUCCESS)
export const taskDetailError = createAction<any>(TASK_DETAIL_ERROR)

export const taskUpdateRequest = createAction<{ id: string; type: string; body: TaskFormInputs }>( //Va otro tipo de dato
  TASK_UPDATE_REQUEST,
)
export const taskUpdateSuccess = createAction<Tasks>(TASK_UPDATE_SUCCESS)
export const taskUpdateError = createAction<any>(TASK_UPDATE_ERROR)

export const taskCreateRequest = createAction<{ body: NewTask; type: string }>(TASK_CREATE_REQUEST) //Hay algo mejor que new task?
export const taskCreateSuccess = createAction<Tasks>(TASK_CREATE_SUCCESS)
export const taskCreateError = createAction<any>(TASK_CREATE_ERROR)

export const exceptionsCreateRequest = createAction<ExceptionData>(EXCEPTIONS_CREATE_REQUEST)
export const exceptionsCreateSuccess = createAction(EXCEPTIONS_CREATE_SUCCESS)
export const exceptionsCreateError = createAction<any>(EXCEPTIONS_CREATE_ERROR)

export const taskDeleteRequest = createAction<{ id: number; type: string }>(TASK_DELETE_REQUEST)
export const taskDeleteSuccess = createAction<deletedItem>(TASK_DELETE_SUCCESS)
export const taskDeleteError = createAction<any>(TASK_DELETE_ERROR)

export const tasksCleanSuccessRequest = createAction(TASK_CLEAN_SUCCESS_REQUEST)
export const tasksCleanErrorRequest = createAction(TASK_CLEAN_ERROR_REQUEST)
