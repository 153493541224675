import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { rcuDetailError, rcuDetailSuccess } from '../../../actions/rcus/actions'
import { Rcu, Id } from '../../../types'
import { getRcuDetail } from '../../../api/rcuDetail'

export function* sagaGetRcuDetail({ payload }: Action<Id>) {
  try {
    const response: Rcu = yield call(getRcuDetail, payload)
    yield put(rcuDetailSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(rcuDetailError(error.response.data as unknown))
    } else {
      yield put(rcuDetailError(error))
      throw error
    }
  }
}
