import { useTranslation } from 'react-i18next'
import usePrivilege from '../../hooks/usePrivilege'
import { Privileges } from '../../enum'
import {
  Button,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Edit, Delete } from '@mui/icons-material'
import { Rcu } from '../types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { rcuDeleteRequest } from '../actions/rcus/actions'

interface RcusTablesProps {
  rcus: Rcu[]
}

const RcusTables = ({ rcus }: RcusTablesProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const dispatch = useDispatch()
  const handleDelete = (id: number) => {
    dispatch(rcuDeleteRequest(id))
  }

  return (
    <TableContainer component={Paper}>
      <Divider />
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="center"> {t('device_id')}</TableCell>
            <TableCell align="center">{t('desc')}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rcus?.map((rcu: Rcu) => (
            <TableRow key={rcu.id} data-e2e={'rcus-row' + rcu.id}>
              <TableCell align="center" component="th" scope="row" data-e2e={'rcus-cell-device_id'}>
                {rcu.device_id}
              </TableCell>
              <TableCell align="center" data-e2e={'rcus-cell-desc'}>
                {rcu.description}
              </TableCell>
              <TableCell align="right">
                <Grid>
                  {hasPrivilege(Privileges.EDIT_DEVICE) && (
                    <Button
                      component={Link}
                      to={`/rcus/${rcu.id}`}
                      data-e2e={`rcus-btn-edit-${rcu.id}`}
                    >
                      <Edit color="primary" />
                    </Button>
                  )}
                  {hasPrivilege(Privileges.DELETE_DEVICE) && (
                    <Button
                      variant="text"
                      onClick={() => handleDelete(rcu.id)}
                      data-e2e={`rcus-btn-dlt-${rcu.id}`}
                    >
                      <Delete color="error" />
                    </Button>
                  )}
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default RcusTables
