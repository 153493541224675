import { useSSE } from 'react-hooks-sse'
import { useSelector } from 'react-redux'
import { getUserProfileState } from '../../common/store/selectors/profiles'
import { DeviceEvent } from '../../types'
import { Box, Typography } from '@mui/material'
import DeviceButton from './DeviceButton'

const showMACinDeviceButton = false

export const InputDevices = () => {
  const { devices: devicesIdStrings, isLoading } = useSelector(getUserProfileState)
  const allDevices = useSSE<DeviceEvent[]>('devices', [])
  const devices = allDevices.filter((device) => device.type !== 'rcu')

  const getDevices = (devicesIdStrings: string[]) => {
    const devicesIds = devicesIdStrings.map(Number)
    const outputDevices = devices.filter(
      (device) => device.type === 'microphone' && devicesIds.includes(device.id),
    )
    return (
      <>
        {outputDevices.map((device) => (
          <Box key={device.id} m={2}>
            <DeviceButton viewMode="large-icon" device={device} size={72} />
            <Typography variant="caption" display="flex" justifyContent="center">
              {device.description}
            </Typography>
            {showMACinDeviceButton ? (
              <Typography variant="caption" display="flex" justifyContent="center">
                {device.device_id}
              </Typography>
            ) : (
              <></>
            )}
          </Box>
        ))}
      </>
    )
  }

  return (
    <Box display="flex">
      <>{!isLoading && devicesIdStrings && <>{getDevices(devicesIdStrings)}</>}</>
    </Box>
  )
}
