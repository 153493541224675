import { Box, Button, ButtonProps, Tooltip } from '@mui/material'
import ContextMenu, { MenuItemActions } from './ContextMenu'

interface IProps extends ButtonProps {
  rightMenu?: MenuItemActions[]
  widthAndHeight?: number | string
  loading?: boolean
  margin?: string
  tooltip?: string
}

const ButtonPanel = ({
  widthAndHeight = 72,
  loading = false,
  margin = '',
  rightMenu,
  ...props
}: IProps) => {
  return (
    <ContextMenu menu={rightMenu} toClose={true}>
      <Box
        display="flex"
        width={widthAndHeight}
        height={widthAndHeight}
        justifyContent="center"
        alignItems="center"
        margin={margin}
      >
        <Box
          width="inherit"
          height="inherit"
          sx={{
            zIndex: -1,
            position: 'absolute',
            borderRadius: '10%',
            overflow: 'hidden',
          }}
        >
          <Box
            display={loading ? 'block' : 'none'}
            width="200%"
            height="200%"
            sx={{
              zIndex: -100,
              position: 'absolute',
              top: '-50%',
              left: '-50%',
              borderRadius: '10%',
              background:
                'linear-gradient(to right, #fff 20%, #f7ebce 40%, #ECD08C 50%, #ECD08C 55%, #f7ebce 70%, #fff 100%)',
              animation: 'shine 4s linear infinite',
              '@keyframes shine': {
                to: {
                  transform: 'rotate(360deg)',
                },
              },
            }}
          ></Box>
        </Box>
        {props.tooltip ? (
          <Tooltip title={props.tooltip}>
            <Button
              sx={{
                borderRadius: '10%',
                width: '95%',
                height: '95%',
                padding: 0,
              }}
              {...props}
            >
              <Box display="flex" width="100%" height="100%" p={0}>
                {props.children}
              </Box>
            </Button>
          </Tooltip>
        ) : (
          <Button
            sx={{
              borderRadius: '10%',
              width: '95%',
              height: '95%',
              padding: 0,
            }}
            {...props}
          >
            <Box display="flex" width="100%" height="100%" p={0}>
              {props.children}
            </Box>
          </Button>
        )}
      </Box>
    </ContextMenu>
  )
}

export default ButtonPanel
