import { call, put } from '@redux-saga/core/effects'
import { groupDeleteSuccess, groupDeleteError } from '../actions/groups/actions'
import axios from 'axios'
import { Action } from './root'
import { deleteGroup } from '../../api/groups/groupDelete'
import { deletedItem } from '../../../hip/types'

export function* sagaDeleteGroup({ payload }: Action<number>) {
  try {
    const response: deletedItem = yield call(deleteGroup, payload)
    yield put(groupDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(groupDeleteError(error.response.data as unknown))
    } else {
      yield put(groupDeleteError(error))
      throw error
    }
  }
}
