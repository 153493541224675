import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../root'
import { stopFileError } from '../../actions/files/actions'
import { stopFile } from '../../../api/files/stopFile'

export function* sagaStopFile({ payload }: Action<{ process: string }>) {
  try {
    yield call(stopFile, payload)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(stopFileError(error.response.data as unknown))
    } else {
      yield put(stopFileError(error))
      throw error
    }
  }
}
