import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from './../root'
import { deviceCreate } from '../../../api/devices/deviceCreate'
import { deviceCreateError, deviceCreateSuccess } from '../../actions/devices/actions'
import { IpDevice, NewIpDevice } from '../../../../types'

export function* sagaDeviceCreate({ payload }: Action<{ body: NewIpDevice; type: string }>) {
  try {
    const response: IpDevice = yield call(deviceCreate, payload)
    yield put(deviceCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(deviceCreateError(error.response.data as unknown))
    } else {
      yield put(deviceCreateError(error))
      throw error
    }
  }
}
