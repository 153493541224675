import { call, put } from '@redux-saga/core/effects'
import { Action } from './root'
import axios from 'axios'
import { ExceptionData } from '../../../types'
import { exceptionsCreateSuccess, exceptionsCreateError } from '../actions/tasks/actions'
import { createExceptions } from '../../api/tasks/exceptionsCreate'

export function* sagaCreateExceptions({ payload }: Action<ExceptionData>) {
  try {
    yield call(createExceptions, payload)
    yield put(exceptionsCreateSuccess())
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(exceptionsCreateError(error.response.data as unknown))
    } else {
      yield put(exceptionsCreateError(error))
      throw error
    }
  }
}
