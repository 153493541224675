import { PayloadAction, createReducer } from '@reduxjs/toolkit'
import { messagesError, messagesRequest, messagesSuccess } from '../actions/messages/action'
import { IFile } from '../../../types'

interface MessagesState {
  messages: IFile[]
  isLoading: boolean
  error: string
}

export const initialState = (): MessagesState => ({
  messages: [],
  isLoading: false,
  error: null,
})

export const messagesReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(messagesRequest, (state) => {
      state.isLoading = true
    })
    .addCase(messagesSuccess, (state, { payload }: PayloadAction<IFile[]>) => {
      state.messages = payload
      state.isLoading = false
    })
    .addCase(messagesError, (state, { payload }) => {
      console.error('messagesReducer - messagesError', payload)
      state.error = JSON.stringify(payload)
      state.isLoading = false
    })
})
