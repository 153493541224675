import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { IFile } from '../../../types'
import { messagesError, messagesSuccess } from '../actions/messages/action'
import { getMessages } from '../../api/messages/messagesGetter'

export function* sagaGetMessages() {
  try {
    const response: IFile[] = yield call(getMessages)
    yield put(messagesSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(messagesError(error.response.data as unknown))
    } else {
      yield put(messagesError(error))
      throw error
    }
  }
}
