import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../root'
import { playFileError } from '../../actions/files/actions'
import { playMusicFile } from '../../../api/files/playMusicFile'

export function* sagaPlayMusicFile({
  payload,
}: Action<{ type: string; group: string; file: string }>) {
  try {
    yield call(playMusicFile, payload)
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(playFileError(error.response.data as unknown))
    } else {
      yield put(playFileError(error))
      throw error
    }
  }
}
