export const LICENSE_REQUEST = '@api/LICENSE_REQUEST'
export const LICENSE_SUCCESS = '@api/LICENSE_SUCCESS'
export const LICENSE_ERROR = '@api/LICENSE_ERROR'

export const UPDATE_LICENSE_REQUEST = '@api/UPDATE_LICENSE_REQUEST'
export const UPDATE_LICENSE_SUCCESS = '@api/UPDATE_LICENSE_SUCCESS'
export const UPDATE_LICENSE_ERROR = '@api/UPDATE_LICENSE_ERROR'

export const LICENSE_CLEAN_SUCCESS_REQUEST = '@api/LICENSE_CLEAN_SUCCESS_REQUEST'
export const LICENSE_CLEAN_ERROR_REQUEST = '@api/LICENSE_CLEAN_ERROR_REQUEST'
