import { createTheme } from '@mui/material'
import palette from './palette'

const SPACING = 4

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      lg: 1280,
      md: 992,
      sm: 768,
      xl: 1920,
      xs: 0,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          lineHeight: 'normal',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: palette.grey.medium,
        },
      },
    },
  },
  palette,
  shape: {
    borderRadius: 4,
  },
  spacing: SPACING,
})

//import {SvgIconPropsColorOverrides} from '@mui/material/SvgIcon'
declare module '@mui/material/SvgIcon' {
  interface SvgIconPropsColorOverrides {
    statusOn: true
    statusOff: true
  }
}
// export const typography = getTypographyOverrides(theme)

// theme.typography = { ...theme.typography, ...typography } as Typography

export default theme
