import createSagaMiddleware from 'redux-saga'
import { configureStore } from '@reduxjs/toolkit'
import root from './reducers/root'
import rootSaga from './sagas/root'

const sagaMiddleware = createSagaMiddleware()

const store = configureStore({ middleware: [sagaMiddleware], reducer: root })

sagaMiddleware.run(rootSaga)

export default store

export type RootState = ReturnType<typeof store.getState>
