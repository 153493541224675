import { Box, Typography } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'
import { LicenseState } from '../common/store/reducers/license'

interface IProps {
  licenseState: LicenseState
}

export const LicenseIndicator = (props: IProps) => {
  return props.licenseState.isLoading ? (
    <CircularProgress />
  ) : (
    <Box>
      <Typography variant="h5">{`Devices: ${props.licenseState.devices}`}</Typography>
      {props.licenseState.expired && (
        <Typography variant="h5">{`Expired: ${props.licenseState.expired}`}</Typography>
      )}
    </Box>
  )
}
