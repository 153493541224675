import { createReducer } from '@reduxjs/toolkit'
import {
  licenseError,
  licenseRequest,
  licenseSuccess,
  updateLicenseError,
  updateLicenseRequest,
  updateLicenseSuccess,
} from '../actions/license/action'

export interface LicenseState {
  devices?: number
  expired?: string
  isLoading: boolean
  success: string
  error: string
}

export const initialState = (): LicenseState => ({
  devices: undefined,
  expired: undefined,
  isLoading: false,
  success: '',
  error: '',
})

export const licenseReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(licenseRequest, (state) => {
      state.devices = undefined
      state.expired = undefined
      state.isLoading = true
    })
    .addCase(licenseSuccess, (state, { payload }) => {
      state.devices = payload.devices
      state.expired = payload.expire
      state.isLoading = false
    })
    .addCase(licenseError, (state, { payload }) => {
      console.error('licenseReducer - licenseError', payload)
      state.error = JSON.stringify(payload)
      state.devices = undefined
      state.expired = undefined
      state.isLoading = false
    })
    .addCase(updateLicenseRequest, (state) => {
      state.devices = undefined
      state.expired = undefined
      state.isLoading = true
      state.error = null
    })
    .addCase(updateLicenseSuccess, (state, { payload }) => {
      state.devices = payload.devices
      state.expired = payload.expire
      state.isLoading = false
      state.error = null
    })
    .addCase(updateLicenseError, (state, { payload }) => {
      console.error('licenseReducer - updateLicensesError', payload)
      state.error = JSON.stringify(payload)
      state.isLoading = false
    })
})
