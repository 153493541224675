import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { RadioFormValues } from '../../../../radio_manager/RadiosForm'
import { Radio } from '../../../../types'
import { radioCreate } from '../../../api/files/createRadio'
import { radioCreateError, radioCreateSuccess } from '../../actions/files/actions'

export function* sagaRadioCreate({ payload }: Action<RadioFormValues>) {
  try {
    const response: Radio = yield call(radioCreate, payload)
    yield put(radioCreateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(radioCreateError(error.response.data as unknown))
    } else {
      yield put(radioCreateError(error))
      throw error
    }
  }
}
