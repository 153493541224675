import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePrivilege from '../hooks/usePrivilege'
import { Privileges } from '../enum'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material'
import { Delete, PauseOutlined, PlayArrow } from '@mui/icons-material'
import { Radio } from '../types'
import { Id } from '../hip/types'

interface FilesTablesProps {
  radios: Radio[]
  handleConfirmDelete: (id: Id) => void
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  handleClose: () => void
}

const RadiosTable = ({
  radios,
  handleConfirmDelete,
  open,
  setOpen,
  handleClose,
}: FilesTablesProps) => {
  const { t } = useTranslation()
  const { hasPrivilege } = usePrivilege()
  const [deviceToDelete, setDeviceToDelete] = useState<number>(null)
  const [audio, setAudio] = useState(new Audio(''))

  const handleClick = (id: Id) => {
    setOpen(true)
    setDeviceToDelete(id)
  }

  const playSound = (radio: Radio, e: any) => {
    audio.src = radio.url
    setAudio(audio)
    audio.play()
    audio.onended = () => {
      e.target.click()
    }
  }

  const stopSound = () => {
    audio.pause()
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Divider />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t('name')}</TableCell>
              <TableCell align="center">{t('url')}</TableCell>
              <TableCell align="center"></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {radios.map((radio) => (
              <TableRow key={radio.id}>
                <TableCell align="center" component="th" scope="row">
                  {radio.name}
                </TableCell>
                <TableCell align="center">{radio.url}</TableCell>
                <TableCell align="center">
                  <Grid>
                    {hasPrivilege(Privileges.DELETE_RADIOS) && (
                      <Button
                        variant="text"
                        onClick={() => handleClick(radio.id)}
                        data-e2e="file-mngr-cell-delete"
                      >
                        <Delete color="error" />
                      </Button>
                    )}
                  </Grid>
                </TableCell>
                <TableCell align="center">
                  <Grid>
                    <Checkbox
                      icon={<PauseOutlined />}
                      checkedIcon={<PlayArrow />}
                      defaultChecked={true}
                      onChange={(e) => {
                        !e.target.checked ? playSound(radio, e) : stopSound()
                      }}
                    />
                  </Grid>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        data-e2e="delete-confirm-dlg"
      >
        <DialogTitle id="responsive-dialog-title">{t('files_delete_title')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{t('files_delete_ask')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleConfirmDelete(deviceToDelete)}
            variant="outlined"
            color="secondary"
            data-e2e="delete-confirm-btn"
          >
            {t('delete')}
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClose}
            autoFocus
            data-e2e="delete-cancel-btn"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default RadiosTable
