import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../root'
import { radioDeleteError, radioDeleteSuccess } from '../../actions/files/actions'
import { Id } from '../../../../hip/types'
import { deleteRadio } from '../../../api/files/deleteRadio'

export function* sagaDeleteRadio({ payload }: Action<{ id: Id }>) {
  try {
    const response: { id: string } = yield call(deleteRadio, payload)
    yield put(radioDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(radioDeleteError(error.response.data as unknown))
    } else {
      yield put(radioDeleteError(error))
      throw error
    }
  }
}
