import { Button, ButtonProps } from '@mui/material'
import { Done } from '@mui/icons-material'
import { IEntityViewMode, entityViewOkBtnName } from '../Utils'
import { useTranslation } from 'react-i18next'

interface IProps extends ButtonProps {
  viewMode: IEntityViewMode
  'data-e2e-prefix'?: string
}
export const EntityViewOkBtn = (props: IProps) => {
  const { t } = useTranslation()
  const { viewMode, 'data-e2e-prefix': prefix, ...btProps } = props

  const btnName = entityViewOkBtnName(viewMode)
  const e2ePrefix = prefix ? prefix + '-' : ''
  const e2e = e2ePrefix + 'btn-' + btnName

  return (
    <Button
      data-e2e={e2e}
      {...btProps}
      variant={props.variant ?? 'contained'}
      color={props.color ?? 'primary'}
      startIcon={props.startIcon ?? <Done />}
    >
      {props.children ?? t(btnName)}
    </Button>
  )
}
