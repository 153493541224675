import { createReducer } from '@reduxjs/toolkit'
import {
  filesError,
  msgFilesRequest,
  musicFilesRequest,
  msgFilesSuccess,
  musicFilesSuccess,
  fileUploadRequest,
  fileUploadSuccess,
  fileUploadError,
  filesCleanSuccessRequest,
  filesCleanErrorRequest,
  fileDeleteRequest,
  fileDeleteSuccess,
  fileDeleteError,
  playFileError,
  stopFileError,
  radiosRequest,
  radiosSuccess,
  radiosError,
  radioDeleteRequest,
  radioDeleteSuccess,
  radioDeleteError,
  radioCreateSuccess,
  radioCreateRequest,
  radioCreateError,
} from '../actions/files/actions'
import { IFile, Radio } from '../../../types'

interface FilesState {
  msgFiles: IFile[]
  musicFiles: IFile[]
  radios: Radio[]
  file: IFile
  isLoading: boolean
  error: string
  success: string
}

export const initialState = (): FilesState => ({
  msgFiles: [],
  musicFiles: [],
  radios: [],
  file: null,
  isLoading: false,
  error: '',
  success: '',
})

export const filesReducer = createReducer(initialState(), (builder) => {
  builder
    .addCase(radiosRequest, (state) => {
      state.isLoading = true
    })
    .addCase(radiosSuccess, (state, { payload }) => {
      state.isLoading = false
      state.radios = payload
    })
    .addCase(radiosError, (state, { payload }) => {
      console.error('filesReducer - filesError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(radioCreateRequest, (state) => {
      state.isLoading = true
    })
    .addCase(radioCreateSuccess, (state, { payload }) => {
      state.radios.unshift(payload)
      state.isLoading = false
      state.success = 'Successfully added'
    })
    .addCase(radioCreateError, (state, { payload }) => {
      console.error('filesReducer - radioCreateError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(radioDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(radioDeleteSuccess, (state, { payload }) => {
      const { id } = payload
      state.radios = state.radios.filter((radio) => radio.id !== Number(id))
      state.success = 'Successfully deleted'
      state.isLoading = false
    })
    .addCase(radioDeleteError, (state, { payload }) => {
      console.error('filesReducer - radioDeleteError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(msgFilesRequest, (state) => {
      state.isLoading = true
    })
    .addCase(msgFilesSuccess, (state, { payload }) => {
      state.isLoading = false
      state.msgFiles = payload
    })
    .addCase(musicFilesRequest, (state) => {
      state.isLoading = true
    })
    .addCase(musicFilesSuccess, (state, { payload }) => {
      state.isLoading = false
      state.musicFiles = payload
    })
    .addCase(filesError, (state, { payload }) => {
      console.error('filesReducer - filesError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(fileUploadRequest, (state) => {
      state.isLoading = true
    })
    .addCase(fileUploadSuccess, (state, { payload }) => {
      const { file, type } = payload
      if (type === 'msg-files') state.msgFiles.unshift(file)
      if (type === 'music-files') state.musicFiles.unshift(file)
      state.isLoading = false
      state.success = 'Successfully added'
    })
    .addCase(fileUploadError, (state, { payload }) => {
      console.error('filesReducer - fileUploadError', payload)
      state.isLoading = false
      state.error = payload
    })
    .addCase(playFileError, (state, { payload }) => {
      console.error('filesReducer - playFileError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(stopFileError, (state, { payload }) => {
      console.error('filesReducer - stopFileError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(fileDeleteRequest, (state) => {
      state.isLoading = true
    })
    .addCase(fileDeleteSuccess, (state, { payload }) => {
      const { id, type } = payload
      if (type === 'msg-files')
        state.msgFiles = state.msgFiles.filter((file) => file.id !== Number(id))
      if (type === 'music-files')
        state.musicFiles = state.musicFiles.filter((file) => file.id !== Number(id))
      state.success = 'Successfully deleted'
      state.isLoading = false
    })
    .addCase(fileDeleteError, (state, { payload }) => {
      console.error('filesReducer - fileDeleteError', payload)
      state.error = payload
      state.isLoading = false
    })
    .addCase(filesCleanSuccessRequest, (state) => {
      state.success = ''
    })
    .addCase(filesCleanErrorRequest, (state) => {
      state.error = ''
    })
})
