import { call, put } from '@redux-saga/core/effects'
import { groupError, groupSuccess } from '../actions/groups/actions'
import { Group } from '../../api/types'
import axios from 'axios'
import { Action } from './root'
import { getGroup } from '../../api/groups/groupGetter'

export function* sagaGetGroup({ payload }: Action<number>) {
  try {
    const response: Group = yield call(getGroup, payload)
    yield put(groupSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(groupError(error.response.data as unknown))
    } else {
      yield put(groupError(error))
      throw error
    }
  }
}
