import { call, put } from '@redux-saga/core/effects'
import { IpDevice } from '../../../../types'
import { Action } from '../root'
import axios from 'axios'
import { deviceUpdateError, deviceUpdateSuccess } from '../../actions/devices/actions'
import { updateDevice } from '../../../api/devices/deviceUpdate'

export function* sagaDeviceUpdate({ payload }: Action<{ body: any; id: number; type: string }>) {
  try {
    const response: IpDevice = yield call(updateDevice, payload)
    yield put(deviceUpdateSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(deviceUpdateError(error.response.data as unknown))
    } else {
      yield put(deviceUpdateError(error))
      throw error
    }
  }
}
