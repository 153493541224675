import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { Action } from '../../../../common/store/sagas/root'
import { deletedItem, roomId } from '../../../types'
import { roomDelete } from '../../../api/roomDelete'
import { roomDeleteSuccess, roomDeleteError } from '../../../actions/rooms/actions'

export function* sagaRoomDelete({ payload }: Action<roomId>) {
  try {
    const response: deletedItem = yield call(roomDelete, payload)
    yield put(roomDeleteSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(roomDeleteError(error.response.data as unknown))
    } else {
      yield put(roomDeleteError(error))
      throw error
    }
  }
}
