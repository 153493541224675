import { IProcess } from '../../types'
import StopCircleIcon from '@mui/icons-material/StopCircle'
import { CircularProgress, IconButton } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { stopFileRequest } from '../../common/store/actions/files/actions'

interface IProps {
  process: IProcess
}

const PlayerItem = ({ process: { process_id, data } }: IProps) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()

  const handleStopClick = () => {
    setLoading(true)
    dispatch(stopFileRequest({ process: process_id }))
  }

  const dataNew = JSON.parse(data)
  const formatTitle = () => {
    const name = dataNew?.name || ''
    const group = dataNew?.group || ''
    return removeDir(name) + (group ? ` - ${group}` : '')
  }

  const removeDir = (path: string): string => {
    return path.replace(/^(music|msg)_files\//, '')
  }

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box>
        <IconButton color="error" onClick={handleStopClick} disabled={loading}>
          {!loading ? (
            <StopCircleIcon fontSize="large" />
          ) : (
            <CircularProgress size={32} color="inherit" />
          )}
        </IconButton>
      </Box>
      <Box>{formatTitle()}</Box>
    </Box>
  )
}

export default PlayerItem
