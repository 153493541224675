import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// eslint-disable-next-line import/no-named-as-default-member
i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: 'en',
  resources: {
    en: {
      translations: require('./locales/en/translations.json'),
    },
    es: {
      translations: require('./locales/he/translations.json'),
    },
  },
  ns: ['translations'],
  defaultNS: 'translations',
})

i18n.languages = ['en', 'he']

export default i18n
