import { useEffect, useState } from 'react'
import { Box, Card, Divider, IconButton, MenuItem, Theme, Typography } from '@mui/material'
import { PlayCircle, SpeakerGroup } from '@mui/icons-material'
import { IProcess } from '../../types'
import { useForm, useWatch } from 'react-hook-form'
import FormSelect from '../../components/FormSelect'
import { useSSE } from 'react-hooks-sse'
import PlayerItem from './PlayerItem'
import { groupsStates } from '../../common/store/selectors/groups'
import { useDispatch, useSelector } from 'react-redux'
import {
  musicFilesRequest,
  msgFilesRequest,
  playMsgFileRequest,
  playMusicFileRequest,
  radiosRequest,
} from '../../common/store/actions/files/actions'
import { filesStates } from '../../common/store/selectors/files'

const styles = {
  header: (theme: Theme) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: 2,
  }),
}

interface IFormInputs {
  group?: string
  file?: string
}

interface IProps {
  type: 'music-files' | 'msg-files'
  title: string
}

const MusicPlayerBox = ({ type, title }: IProps) => {
  const dispatch = useDispatch()
  const e2e = 'music-player-box'
  const process = useSSE<IProcess[]>(type === 'music-files' ? 'process' : 'messages', [])
  const { control, handleSubmit, reset } = useForm<IFormInputs>({})
  const zonesState = useSelector(groupsStates)
  const { radios, msgFiles, musicFiles, isLoading } = useSelector(filesStates)
  const [isPlaying, setIsPlaying] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)

  useEffect(() => {
    dispatch(musicFilesRequest({ type: 'music-files' }))
    dispatch(msgFilesRequest({ type: 'msg-files' }))
    dispatch(radiosRequest())
  }, [])

  useEffect(() => {
    console.log('isLoading')
  }, [isLoading])

  useEffect(() => {
    setIsPlaying(process.length > 0)
    reset({
      group: '',
      file: '',
    })
  }, [process])

  const zone = useWatch({
    control,
    name: 'group',
    defaultValue: undefined,
  })

  const file = useWatch({
    control,
    name: 'file',
    defaultValue: undefined,
  })

  const submitForm = (data: IFormInputs) => {
    if (type === 'music-files') {
      const req = { group: data.group, file: data.file }
      dispatch(playMusicFileRequest(req))
    }
    if (type === 'msg-files') {
      const req = { file: data.file }
      dispatch(playMsgFileRequest(req))
    }
    setTimeout(() => {
      setButtonDisabled(false)
    }, 2000)
  }

  const SelectFiles = () => {
    if (type === 'msg-files') {
      return (
        <Box ml={2} mr={2} width="100%">
          <FormSelect control={control} id={'file'} label="Files" size="small" defaultValue="">
            {msgFiles.map((file) => (
              <MenuItem key={file.id} value={'msg_files/' + file.id} data-e2e={e2e + '-file-item'}>
                {`${file.name} - ${file.duration}s`}
              </MenuItem>
            ))}
          </FormSelect>
        </Box>
      )
    }
    if (type === 'music-files') {
      return (
        <Box ml={2} mr={2} width="100%" data-e2e={e2e + '-form-select'}>
          <FormSelect
            control={control}
            id={'file'}
            label="Playlist"
            size="small"
            fullWidth
            defaultValue=""
          >
            {radios.map((radio) => (
              <MenuItem key={radio.id} value={'radio/' + radio.id} data-e2e={e2e + '-radio-item'}>
                {radio.name}
              </MenuItem>
            ))}
            {musicFiles.map((file) => (
              <MenuItem
                key={file.id}
                value={'music_files/' + file.id}
                data-e2e={e2e + '-file-item'}
              >
                {`${file.name} - ${file.duration}s`}
              </MenuItem>
            ))}
          </FormSelect>
        </Box>
      )
    }
    return <></>
  }

  const enableSubmit = type === 'music-files' ? !!(zone && file) : !!file

  return (
    <Card elevation={4} data-e2e={e2e}>
      <Box display="flex" flexDirection="row" sx={styles.header}>
        <SpeakerGroup />
        <Typography variant="h5">{title}</Typography>
      </Box>
      <Divider />
      <form onSubmit={handleSubmit(submitForm)}>
        <Box mt={2} mb={2} display="flex" displayPrint="row" alignItems="center">
          <Box>
            <IconButton
              color="primary"
              type="submit"
              disabled={!enableSubmit || isPlaying || buttonDisabled}
              onClick={() => {
                setButtonDisabled(true)
                handleSubmit(submitForm)()
              }}
            >
              <PlayCircle fontSize="large" />
            </IconButton>
          </Box>
          {type === 'music-files' && (
            <Box ml={2} mr={2} width="100%">
              <FormSelect
                control={control}
                id={'group'}
                label="Zones"
                size="small"
                fullWidth
                defaultValue=""
              >
                {zonesState &&
                  zonesState.groups.map((zone) => (
                    <MenuItem key={zone.id} value={zone.id}>
                      {zone.name}
                    </MenuItem>
                  ))}
              </FormSelect>
            </Box>
          )}
          <SelectFiles />
        </Box>
      </form>
      <Divider />
      <Box>
        {process.map((p) => (
          <PlayerItem key={p.process_id} process={p} />
        ))}
      </Box>
    </Card>
  )
}

export default MusicPlayerBox
