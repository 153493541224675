import { createAction } from '@reduxjs/toolkit'
import { IUserProfile, IUserProfileRequest } from '../../../api/types'
import {
  USER_PROFILE_REQUEST,
  USER_PROFILE_SUCCESS,
  USER_PROFILE_ERROR,
  USER_PROFILE_CLEAN_SUCCESS_REQUEST,
  USER_PROFILE_CLEAN_ERROR_REQUEST,
} from './actionTypes'

export const userProfileRequest = createAction<IUserProfileRequest>(USER_PROFILE_REQUEST)
export const userProfileSuccess = createAction<IUserProfile>(USER_PROFILE_SUCCESS)
export const userProfileError = createAction<any>(USER_PROFILE_ERROR)

export const userProfileCleanSuccessRequest = createAction(USER_PROFILE_CLEAN_SUCCESS_REQUEST)
export const userProfileCleanErrorRequest = createAction(USER_PROFILE_CLEAN_ERROR_REQUEST)
