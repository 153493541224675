import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { radiosError, radiosSuccess } from '../../actions/files/actions'
import { Radio } from '../../../../types'
import { getRadios } from '../../../api/files/getRadios'

export function* sagaGetRadios() {
  try {
    const response: Radio[] = yield call(getRadios)
    yield put(radiosSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(radiosError(error.response.data as unknown))
    } else {
      yield put(radiosError(error))
      throw error
    }
  }
}
