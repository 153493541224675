import axios from 'axios'
import { call, put } from '@redux-saga/core/effects'
import { Action } from '../../../../common/store/sagas/root'
import { getHipUsersByProfiles } from '../../../api/hipUsersGetter'
import { User_Key } from '../../../types'
import { hip_usersKeysError, hip_usersKeysSuccess } from '../../../actions/hipUsers/actions'

export function* sagaGetUserAndKey({ payload }: Action<number[]>) {
  try {
    const response: User_Key[] = yield call(getHipUsersByProfiles, payload)
    yield put(hip_usersKeysSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(hip_usersKeysError(error.response.data as unknown))
    } else {
      yield put(hip_usersKeysError(error))
      throw error
    }
  }
}
