declare module '@mui/material' {
  interface Color {
    dark: string
    darker: string
    light: string
    main: string
    medium: string
    statusOn: string
    statusOff: string
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    disable: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    black: Palette['primary']
    red: Palette['primary']
    white: Palette['primary']
    statusOn: Palette['primary']
    statusOff: Palette['primary']
  }

  interface PaletteOptions {
    black: PaletteOptions['primary']
    red: PaletteOptions['primary']
    white: PaletteOptions['primary']
    statusOn: PaletteOptions['primary']
    statusOff: PaletteOptions['primary']
  }
}

const RED_MAIN = '#F2002B'

const palette = {
  black: {
    dark: '#041828',
    light: '#58687c',
    main: '#2D3E50',
  },
  error: {
    main: RED_MAIN,
  },
  grey: {
    dark: '#666666',
    darker: '#777777',
    light: '#F6F6F6',
    main: '#EEEEEE',
    medium: '#BBBBBB',
  },
  primary: {
    main: '#0067B2',
  },
  red: {
    main: RED_MAIN,
  },
  secondary: {
    dark: '#006aa9',
    light: '#74c9ff',
    main: '#2D3E50',
  },
  white: {
    dark: '#F1F3F6',
    main: '#FFF',
  },
  disable: {
    main: '#D6D6D6',
    dark: '#C2C2C2',
    contrastText: '#777777',
  },
  statusOn: {
    main: 'yellow',
    dark: 'yellow',
    light: 'yellow',
  },
  statusOff: {
    main: 'gray',
    dark: 'C2C2C2',
    light: 'gray',
  },
}

export default palette
