import { call, put } from '@redux-saga/core/effects'
import axios from 'axios'
import { ContextExceptions } from '../../../types'
import { exceptionsSuccess, exceptionsError } from '../actions/tasks/actions'
import { getExceptions } from '../../api/tasks/exceptionsGetter'

export function* sagaGetExceptions() {
  try {
    const response: ContextExceptions[] = yield call(getExceptions)
    yield put(exceptionsSuccess(response))
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      yield put(exceptionsError(error.response.data as unknown))
    } else {
      yield put(exceptionsError(error))
      throw error
    }
  }
}
