import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  DialogContentText,
} from '@mui/material'
import ButtonPanel from './ButtonPanel'
import { useDispatch, useSelector } from 'react-redux'
import { mqttRequest } from '../../common/store/actions/mqtt/actions'
import { getMqttState } from '../../common/store/selectors/mqtt'
import SendIcon from '@mui/icons-material/Send'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface MqttInfo {
  topic: string
  payload: string
}

const MqttPublishButton = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { isLoading } = useSelector(getMqttState)
  const [open, setOpen] = useState(false)
  const [mqttInfo, setMqttInfo] = useState<MqttInfo | undefined>()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const onClickHandle = (e: React.FormEvent) => {
    e.preventDefault()
    setOpen(false)
    dispatch(mqttRequest({ topic: mqttInfo.topic, payload: mqttInfo.payload }))
  }

  return (
    <>
      <ButtonPanel
        variant="contained"
        widthAndHeight={72}
        color="primary"
        onClick={handleClickOpen}
        loading={isLoading}
        tooltip="Publish MQTT"
        data-e2e="mqtt-publish-btn"
      >
        <Box
          sx={{
            position: 'absolute',
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
            alignItems: 'center',
          }}
        >
          <Box m={4} width="100%" height="100%">
            <SendIcon
              sx={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Box>
      </ButtonPanel>

      <Dialog open={open} onClose={handleClose} data-e2e="mqtt-publish-dlg">
        <form onSubmit={onClickHandle}>
          <DialogTitle>{t('mqtt_generic_publisher_title')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{t('mqtt_generic_publisher_detail')}</DialogContentText>
            <TextField
              required
              autoFocus
              margin="dense"
              id="name"
              label={t('mqtt_generic_publisher_topic')}
              type="Topic"
              fullWidth
              variant="standard"
              value={mqttInfo?.topic || undefined}
              onChange={(e) =>
                setMqttInfo((prevValue) => ({ ...prevValue, topic: e.target.value }))
              }
              data-e2e="mqtt-publish-topic"
            />
            <TextField
              required
              margin="dense"
              id="name"
              label={t('mqtt_generic_publisher_payload')}
              type="Message payload"
              fullWidth
              variant="standard"
              value={mqttInfo?.payload || undefined}
              onChange={(e) =>
                setMqttInfo((prevValue) => ({ ...prevValue, payload: e.target.value }))
              }
              data-e2e="mqtt-publish-payload"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} data-e2e="mqtt-publish-cancel-btn">
              {t('cancel')}
            </Button>
            <Button type="submit" data-e2e="mqtt-publish-send-btn">
              {t('send')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  )
}

export default MqttPublishButton
