import { Box, Button, FormControl, Grid, MenuItem } from '@mui/material'
import { Done, Schedule, Cancel } from '@mui/icons-material'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import FormSelect from '../components/FormSelect'
import CheckboxSelector from '../components/CheckboxSelector'
import { CommonContent } from '../components/CommonContent'
import {
  tasksRequest,
  exceptionsCreateRequest,
  tasksCleanSuccessRequest,
  tasksCleanErrorRequest,
  exceptionsRequest,
} from '../common/store/actions/tasks/actions'
import { useSelector } from 'react-redux/es/hooks/useSelector'
import { tasksStates } from '../common/store/selectors/tasks'
import { ExceptionData } from '../types'

export interface ExceptionFormInputs {
  day_begin: string
  day_end?: string
  context: string
  selectedExceptions: number[]
}

const ExceptionsForm = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { control, handleSubmit, reset } = useForm<ExceptionFormInputs>({})
  const { tasks, exceptions, isLoading, success, error } = useSelector(tasksStates)
  const date = new Date().toISOString().replace('Z', '')

  const [selectedItems, setSelectedItems] = useState<number[]>([])

  useEffect(() => {
    const req = {
      pageSet: { limit: '200', page: '1' },
      type: 'exception_rules',
    }
    dispatch(tasksRequest(req))
    dispatch(exceptionsRequest())
  }, [])

  useEffect(() => {
    console.log('isLoading')
  }, [isLoading])

  useEffect(() => {
    if (success !== '') {
      enqueueSnackbar(t(success), { variant: 'success' })
      dispatch(tasksCleanSuccessRequest())
    } else if (error !== '') {
      enqueueSnackbar(t(error), { variant: 'error' })
      dispatch(tasksCleanErrorRequest())
    }
  }, [success, error])

  const handleContextChange = (selectedContext: string) => {
    const selectedExceptions = exceptions.find(
      (context) => context.context.id === selectedContext,
    )?.exceptions
    setSelectedItems(selectedExceptions)
  }

  const onSubmit = (data: ExceptionFormInputs) => {
    const exceptionData: ExceptionData = {
      from_date: date,
      context: data.context,
      selectedExceptions: selectedItems,
    }
    dispatch(exceptionsCreateRequest(exceptionData))
    reset({
      day_begin: null,
      day_end: null,
      context: null,
    })
    setSelectedItems([])
    dispatch(exceptionsRequest())
  }

  return (
    <CommonContent
      title={t(`add_exception_context`)}
      titleIcon={<Schedule style={{ color: 'white' }} />}
    >
      <FormControl fullWidth sx={{ padding: 8 }}>
        <Box mt={4}>
          <FormSelect
            control={control}
            id="context"
            label={t('context')}
            size="small"
            defaultValue=""
            fullWidth
            onChange={(selectedContext) => handleContextChange(selectedContext)}
            rules={{
              required: {
                value: true,
                message: t('required'),
              },
            }}
          >
            {exceptions.map((context) => (
              <MenuItem key={context.context.id} value={context.context.id}>
                {context.context.name}
              </MenuItem>
            ))}
          </FormSelect>
        </Box>
        <Box mt={4} border={'1px solid lightgrey'} borderRadius="5px" m={4} p={5}>
          <CheckboxSelector
            items={tasks.items}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
          />
        </Box>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button
              data-e2e="task-btn-cancel"
              variant="contained"
              color="inherit"
              onClick={() => navigate(`/tasks/exception_rules`)}
              startIcon={<Cancel />}
            >
              {t('cancel')}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              disabled={!selectedItems.length}
              onClick={() => handleSubmit(onSubmit)()}
              startIcon={<Done />}
            >
              {t('add')}
            </Button>
          </Grid>
        </Grid>
      </FormControl>
    </CommonContent>
  )
}

export default ExceptionsForm
