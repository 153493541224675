import { Checkbox, FormControlLabel } from '@mui/material'

import { ICheckboxItem, IControlCheckboxList } from './types'

const ControlCheckboxList = ({ items, field, ...props }: IControlCheckboxList) => {
  const hasItem = (item: ICheckboxItem, values: ICheckboxItem[]): boolean => {
    return values.some((i) => i.id === item.id)
  }

  return (
    <>
      {items.map((item) => {
        return (
          <FormControlLabel
            {...field}
            control={
              <Checkbox
                checked={hasItem(item, field.value)}
                data-e2e={props['data-e2e'] ? `${props['data-e2e']}-${item.id}` : undefined}
                onChange={() => {
                  if (!hasItem(item, field.value)) {
                    field.onChange([...field.value, item])
                    return
                  }
                  const newTopics = field.value?.filter(
                    (topic: ICheckboxItem) => topic.id !== item.id,
                  )
                  field.onChange(newTopics)
                }}
                sx={{ padding: '2px', paddingRight: '4px' }}
              />
            }
            key={item.id}
            label={item.label}
          />
        )
      })}
    </>
  )
}

export default ControlCheckboxList
